import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { v4 } from 'uuid';
import { OPTIONS_LANGUAGE, OPTIONS_LANGUAGE_LEVEL, EDUCATION_TYPE, OPTIONS_STUDY_FIELD } from '@/constants';
import { saveClinetProfileNote } from '@/service/weexpertService';
import { useTranslation } from 'react-i18next';
import { toast } from '../ui/use-toast';
import { FreelancerProfile } from '../account-components/freelancer.profile';
import { formatDateString } from '@/styles';
import { Textarea } from '../ui/textarea';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card';
import { format } from 'date-fns';
import { useQueryClient } from 'react-query';
import { AlertCircle } from 'lucide-react';
import { AlertTitle } from '@mui/material';
import { Alert } from '../ui/alert';

const FreelanceProfileNotes = ({ notes }) => {
  const notesComponent = notes.map(note => (
    <Card className="mb-4 ">
      <CardHeader>
        <CardTitle className="flex align-center items-center justify-between">
          <div className='flex align-center items-center'>
            <div className='bg-blueLightAccent w-8 h-8 rounded-md flex justify-center items-center text-base font-medium text-blueLink cursor-pointer mr-4'
            >
              {note.user.lastName[0]}
            </div>
            {note.user.firstName} {note.user.lastName}
          </div>
          <CardDescription className="flex justify-end">{format(new Date(note.createdAt), 'dd/MM/yyyy')}</CardDescription>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p>
          {note.note}
        </p>
      </CardContent>
    </Card>

  ));
  return <div className='mt-8'>
    {notesComponent}
  </div>

}
export function FreelancerViewComponent({ userData: profile, id }) {

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { abilities = [] } = profile || []

  const [freelancerProfile, setFrilancerProfile] = useState(true);
  const [edit, setEdit] = useState(false);
  const [jobTitle, setJobTitle] = useState(profile ? profile.currentJobTitle : 'TES');
  const [experience, setExperience] = useState(profile ? profile.currentExperience : '');
  const [ldLink, setLdLink] = useState(profile ? profile.linkedin : '');
  const [showSpecificSkills, setShowSpecificSkills] = useState(false);
  const [inputChecked, setInputChecked] = useState(false);
  const [interestedInProject, setInterestedInProject] = useState(profile ? profile.interestedInProjects : false);

  const [maxHours, setMaxHours] = useState(profile ? profile.availability : '0');
  const [rate, setRate] = useState(profile ? profile.rate : '0');
  const [comment, setComment] = useState('');
  const [selectedSkills, setSelectedSkills] = useState(
    abilities
      .filter(e => e.abilityType == 'Skill')
      .map(e => {
        return {
          id: e.ability.code ?? v4(),
          label: e.ability.name,
          value: e.ability.name
        }
      }) ?? []
  );
  const [selectedTools, setSelectedTools] = useState(
    abilities
      .filter(e => e.abilityType == 'ToolsAndTechnologies')
      .map(e => {
        return {
          id: e.ability.code ?? v4(),
          label: e.ability.name,
          value: e.ability.name
        }
      }) ?? []
  );
  const [selectedProgrammingLanguages, setSelectedProgrammingLanguages] = useState(
    abilities
      .filter(e => e.abilityType == 'ProgrammingLanguage')
      .map(e => {
        return {
          id: e.ability.code ?? v4(),
          label: e.ability.name,
          value: e.ability.name
        }
      }) ?? []
  );
  const [selectedFrameworks, setSelectedFrameworks] = useState(
    abilities
      .filter(e => e.abilityType == 'Frameworks')
      .map(e => {
        return {
          id: e.ability.code ?? v4(),
          label: e.ability.name,
          value: e.ability.name
        }
      }) ?? []
  );
  const [languagesData, setLanguagesData] = useState(
    profile && profile && profile.languages
      ? profile.languages.map(e => {
        const a = {
          id: e.id,
          language: {
            value: OPTIONS_LANGUAGE().filter(p => p.id == e.code)[0]?.id ?? v4(),
            label: e.name
          },
          proficiency:
            OPTIONS_LANGUAGE_LEVEL().filter(p => p.id == e.proficiency).map(s => {
              return {
                value: s.id,
                label: s.value
              }
            })[0] ?? null,
        };
        return a;
      })
      : [{ id: 1, language: null, proficiency: null }]
  );

  const [selectedEducation, setSelectedEducation] = useState(
    profile && profile && profile.educationLevel
      ? EDUCATION_TYPE()
        .filter(q => q.id == profile.educationLevel)
        .map(e => {
          return {
            value: e.id,
            label: e.value
          };
        })[0] ?? null
      : null
  );
  const [selectedStudy, setSelectedStudy] = useState(
    profile && profile && profile.fieldOfStudy
      ? OPTIONS_STUDY_FIELD()
        .filter(q => q.id == profile.fieldOfStudy)
        .map(e => {
          return {
            value: e.id,
            label: e.value
          };
        })[0] ?? null
      : null
  );

  useEffect(() => {
    if (!freelancerProfile) {
      setEdit(false)
    }
  }, [freelancerProfile])

  return (
    <>
      <div className="flex">
        <div className='w-6/12 pt-10 pl-8'>
          <div className='w-72 text-4xl'>
            {`${profile && profile.client.firstName} ${profile && profile.client.lastName}`}
          </div>
          <div className='w-full relative flex flex-row justify-between items-end'>
            <div className='w-72 '>
              <div className='pt-8 flex justify-between items-center'>
                <Button className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none ${freelancerProfile ? 'opacity-70' : ''}`}
                  onClick={() => setFrilancerProfile(false)}
                >
                  {t("users:main_information")}
                </Button>
                <Button className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none ${freelancerProfile ? '' : 'opacity-70'}`}
                  onClick={() => setFrilancerProfile(true)}
                >
                  {t("users:freelancer_profile")}
                </Button>
              </div>
            </div>
          </div>
          <div className="w-72 relative mt-2 h-1 bg-blueBorder">
            <div className={`absolute w-6/12 h-full ${freelancerProfile ? 'right-0' : 'left-0'} top-0 bg-blueLink`}></div>
          </div>
          {
            freelancerProfile && profile ?
              <FreelancerProfile
                userData={profile}
                job={jobTitle}
                setJob={setJobTitle}
                experience={experience}
                setExperience={setExperience}
                ldLink={ldLink}
                setLdLink={setLdLink}
                abilities={abilities}
                setShowSpecificSkills={setShowSpecificSkills}
                selectedSkills={selectedSkills}
                setSelectedSkills={setSelectedSkills}
                selectedTools={selectedTools}
                setSelectedTools={setSelectedTools}
                selectedProgrammingLanguages={selectedProgrammingLanguages}
                setSelectedProgrammingLanguages={setSelectedProgrammingLanguages}
                selectedFrameworks={selectedFrameworks}
                setSelectedFrameworks={setSelectedFrameworks}
                languagesData={languagesData}
                setLanguagesData={setLanguagesData}
                inputChecked={false}
                selectedEducation={selectedEducation}
                setSelectedEducation={setSelectedEducation}
                selectedStudy={selectedStudy}
                setSelectedStudy={setSelectedStudy}
                checked={interestedInProject}
                setChecked={setInterestedInProject}
                currentRate={rate}
                setCurrentRate={setRate}
                maxHours={maxHours}
                setMaxHours={setMaxHours}
                edit={false} />
              :
              (<div className='w-full mt-0'>
                <div className=' pt-10 flex '>
                  <div className='w-6/12'>
                    <div className='pb-2'>{t("name")}</div>
                    <div className='mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                      {profile && profile.client.firstName}
                    </div>
                    <div className='pb-2'>{t("phone")}</div>
                    <div className='mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                      {profile && profile.client.phone}
                    </div>
                    <div className='pb-2'>{t("clients:create_client_placeholder_date_of_birth")}</div>
                    <div className='mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                      {profile && formatDateString(profile.client.dateOfBirth)}
                    </div>
                  </div>
                  <div className='w-6/12 ml-2'>
                    <div className='pb-2 pl-2'>{t("last_name")}</div>
                    <div className=' p-3 mb-3  bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                      {profile && profile.client.lastName}
                    </div>
                    <div className='pb-2 pl-2'>Email</div>
                    <div className='p-3 mb-3  bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                      {profile && profile.client.email}
                    </div>
                  </div>
                </div>
              </div>
              )
          }
        </div>
        <div className='w-6/12 pt-[43px] pl-4 pr-4'>
          <div className='text-4xl pb-1'> {t("comments")}:</div>
          <div>
            <Textarea
              placeholder={t("comments")}
              value={comment}
              onChange={(e) => { setComment(e.target.value) }}
            />
            {inputChecked && !comment && (<div className="mt-4">
              <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
                <AlertCircle className="h-4 w-4 !static !mr-2" />
                <AlertTitle className="m-0 flex align-center items-center h-full !mb-0">
                  {t('empty_data_invalid')}
                </AlertTitle>
              </Alert>
            </div>)
            }
            <div className="flex justify-end mt-4">
              <Button className="w-36" onClick={() => {
                if (!comment) {
                  setInputChecked(true)
                  return
                }
                saveClinetProfileNote(profile.id, comment)
                  .then(result => {
                    toast({
                      title: t("comment_saved")
                    })
                    queryClient.invalidateQueries({ queryKey: ['freelacer', id] })
                  })
                  .then(() => {
                    setComment('');
                    setInputChecked(false)
                  })
              }}>
                {t("button_save")}
              </Button>
            </div>
          </div>
          <FreelanceProfileNotes notes={profile.notes} />
        </div>
      </div>
    </>
  );

}
