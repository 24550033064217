import React, { useEffect, useState } from "react";
import AppBarForLogin from "../components/app-bar-for-main-login-pages/AppBarForLogin";
import '../scssStyles/SurveyStep3.scss';
import { SKILLS_TYPE, TOOLS_AND_TECHNOLOGY_TYPE, PROGRAMMING_LANGUAGE_TYPE, TARGET_KEYS, FRAMEWORK_TYPE, EXISTING_ABILITY_ID } from "../constants";
import { getUser, surveyStep3 } from "../service/weexpertService";
import { whiteButtonWithBorder } from "../styles";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress"
import { v4 } from 'uuid'
import MultipleSelector from "@/components/ui/multiple-selector";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

export const SurveyStep3 = ({ back, next }) => {

    const { t } = useTranslation();
    const { data: userData, isLoading: loading } = useQuery({ queryKey: ['userMeProfile'], queryFn: getUser, retry: false, refetchOnWindowFocus: false })
    const [showSpecificSkills, setShowSpecificSkills] = useState(false);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedTools, setSelectedTools] = useState([]);
    const [selectedProgrammingLanguages, setSelectedProgrammingLanguages] = useState([]);
    const [selectedFrameworks, setSelectedFrameworks] = useState([]);

    const [inputChecked, setInputChecked] = useState(false);
    useEffect(() => {
        const transformByType = (abilities, type) => {
            return abilities
                .filter(e => e.abilityType == type)
                .map(e => {
                    return {
                        id: e.ability.code ?? v4(),
                        label: e.ability.name,
                        value: e.ability.name
                    }
                }) ?? []

        }
        if (loading === false) {
            const { abilities = [] } = userData.data.clientProfile ?? [];
            setSelectedSkills(transformByType(abilities, 'Skill'))
            setSelectedProgrammingLanguages(abilities
                .filter(e => e.abilityType == 'ProgrammingLanguage')
                .map(e => {
                    return {
                        id: e.ability.code ?? v4(),
                        label: e.ability.name,
                        value: e.ability.name
                    }
                }) ?? []
            )
            setSelectedFrameworks(abilities
                .filter(e => e.abilityType == 'Frameworks')
                .map(e => {
                    return {
                        id: e.ability.code ?? v4(),
                        label: e.ability.name,
                        value: e.ability.name
                    }
                }) ?? []
            )
            setSelectedTools(abilities
                .filter(e => e.abilityType == 'ToolsAndTechnologies')
                .map(e => {
                    return {
                        id: e.ability.code ?? v4(),
                        label: e.ability.name,
                        value: e.ability.name
                    }
                }) ?? []
            )
        }
    }, [userData])

    useEffect(() => {
        const isSkillsSelected = selectedSkills.some(skill => TARGET_KEYS.includes(skill.id));
        setShowSpecificSkills(isSkillsSelected);
    }, [selectedSkills]);

    function renameKeys(array) {
        return array.map(obj => {
            return {
                name: obj.label,
                code: EXISTING_ABILITY_ID.includes(obj.id) ? obj.id : null // meaning if we have value == label it is custom created and backend expect it to null as code value for the custom created element
            }
        });
    }

    const onSaveStates = () => {

        setInputChecked(true)
        if (selectedSkills.length < 1) {
            return;
        }
        if (selectedTools.length < 1) {
            return;
        }

        surveyStep3(
            renameKeys(selectedSkills),
            renameKeys(selectedFrameworks),
            renameKeys(selectedTools),
            renameKeys(selectedProgrammingLanguages))
            .then(() => {
                next();
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    console.error('Ошибка 403: Доступ запрещен');
                } else {
                    console.error('Произошла ошибка при получении пользователя:', error.message);
                }
            });
    }

    return (
        <div className={'container'}>

            <AppBarForLogin isLogined={true} userData={userData.data} />
            <div className="pt-24 pb-28" >
                <div className="text-3xl font-semibold w-96 pb-6">
                    {t('survey:step_3_capture')}
                </div>
                <div className="pb-6 text-base">
                    {t('survey:step_3_description')}
                </div>
                <div className="w-1/3 mb-4">
                    <MultipleSelector
                        creatable={true}
                        className="min-h-12 my-2 bg-white"
                        placeholder={t('survey:placeholder_skills')}
                        badgeClassName="bg-emerald-100 text-black"
                        value={selectedSkills}
                        error={inputChecked && selectedSkills.length < 1}
                        errorMessage={t('survey:error_select')}
                        label={t('survey:label_Skills')}
                        onChange={(newSelected) => {
                            setSelectedSkills(newSelected)
                        }}
                        options={SKILLS_TYPE()
                            .map(item => {
                                return {
                                    value: item.label,
                                    label: item.label,
                                    id: item.value
                                }
                            })
                        }
                    />
                    {showSpecificSkills && (
                        <>
                            <MultipleSelector
                                className="min-h-12 my-2 bg-white"
                                creatable={true}
                                placeholder={t('survey:placeholder_languages')}
                                badgeClassName="bg-emerald-100 text-black"
                                value={selectedProgrammingLanguages}
                                label={t('survey:label_programming_languages')}
                                onChange={(newSelected) => {
                                    setSelectedProgrammingLanguages(newSelected)
                                }}
                                options={PROGRAMMING_LANGUAGE_TYPE()
                                    .map(item => {
                                        return {
                                            value: item.label,
                                            label: item.label,
                                            id: item.value
                                        }
                                    })
                                }
                            />
                            <MultipleSelector
                                className="min-h-12 my-2 bg-white"
                                creatable={true}
                                placeholder={t('survey:placeholder_frameworks')}
                                badgeClassName="bg-emerald-100 text-black"
                                value={selectedFrameworks}
                                //error={true}
                                // errorMessage={'please select 1 skill'}
                                label={t('survey:label_frameworks')}
                                onChange={(newSelected) => {
                                    setSelectedFrameworks(newSelected)
                                }}
                                options={FRAMEWORK_TYPE()
                                    .map(item => {
                                        return {
                                            value: item.label,
                                            label: item.label,
                                            id: item.value
                                        }
                                    })
                                }
                            />
                        </>
                    )}
                    <MultipleSelector
                        className="min-h-12 my-2 bg-white"
                        creatable={true}
                        placeholder={t('survey:placeholder_tools')}
                        badgeClassName="bg-emerald-100 text-black"
                        value={selectedTools}
                        error={inputChecked && selectedTools.length < 1}
                        errorMessage={t('survey:error_select')}
                        label={t('survey:label_tools')}
                        onChange={(newSelected) => { setSelectedTools(newSelected) }}
                        options={TOOLS_AND_TECHNOLOGY_TYPE()
                            .map(item => {
                                return {
                                    value: item.label,
                                    label: item.label,
                                    id: item.value
                                }
                            })
                        }
                    />
                </div>
            </div>
            <Progress value={50} />
            <div className=" flex flex-row justify-between	 pt-6">
                <Button sx={{ ...whiteButtonWithBorder, width: '152px', margin: 0 }} onClick={() => { back() }}>{t('survey:button_back')}</Button>
                <Button className='w-36' onClick={() => onSaveStates()}>{t('survey:button_next')}</Button>
            </div>
        </div>
    );
}
