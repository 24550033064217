import { Alert } from '@mui/material';
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ClientForm } from "../components/clients/ClientForm";
import { connectUserToClinet, newClient } from '../service/weexpertService';
import { REDIRECT_DELAY, validateEmail, validateNoneCyrillic, REPRESENTATIVE_COMPANY_LIST, validatePhone } from "../constants";
import { useTranslation } from 'react-i18next';
import { getUsers } from '../service/weexpertService';
import { CLIENT_STATES_ERRORS } from '../constants';
import { Button } from '@/components/ui/button';
import { format } from "date-fns";

export const ClientCreatePage = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/admin/clients');
  };

  const [errorMap, setErrorMap] = useState({});

  const [inputChecked, setInputChecked] = useState(false);
  const [alertMessage, setAllertMessage] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientCitizenship, setClientCitizenship] = useState(null);
  const [clientPesel, setClientPesel] = useState('');
  const [clientIncubatorBankAccounts, setClientIncubatorBankAccounts] = useState([]);
  const [clientBankAccounts, setClientBankAccounts] = useState([]);
  const [clientLastName, setClientLastName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientDateOfBirth, setClientDateOfBirth] = useState(null);
  const [clientPassport, setClientPassport] = useState('');
  const [clientRepresentativeCompany, setClientRepresentativeCompany] = useState(REPRESENTATIVE_COMPANY_LIST[1].id);
  const [clientPostIndex, setClientPostIndex] = useState('');
  const [clientCity, setClientCity] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [assistans, setAssistans] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [e, setE] = useState('');

  const onClickSendButtonHandler = (t) => {
    if (
      (!clientEmail || !validateEmail(clientEmail)) ||
      !clientPassport ||
      (!clientName || !validateNoneCyrillic(clientName)) ||
      (!clientLastName || !validateNoneCyrillic(clientLastName)) ||
      (!clientPhone || !validatePhone(clientPhone)) ||
      (!clientCitizenship) ||
      (!clientCity || !validateNoneCyrillic(clientCity)) ||
      (!clientPostIndex) ||
      (!clientRepresentativeCompany) ||
      (!clientAddress || !validateNoneCyrillic(clientAddress)) ||
      (!clientDateOfBirth) ||
      (!selectedAssistant)
    ) {
      setInputChecked(true)
      return;
    }
    newClient(
      clientName,
      clientLastName,
      clientEmail,
      clientPhone,
      clientPesel,
      clientIncubatorBankAccounts.filter(e => e.account !== ''),
      clientBankAccounts.filter(e => e.account !== ''),
      '',
      clientCity,
      clientPostIndex,
      clientPassport,
      clientAddress,
      '',
      '',
      format(clientDateOfBirth, 'yyyy-MM-dd'),
      clientCitizenship.content.value,
      clientRepresentativeCompany
    )
      .then((response) => {
        return connectUserToClinet(response.data.id, selectedAssistant.value)
          .then(() => {
            if (response.status === 200) {
              setAllertMessage(alertSucces);
              setTimeout(() => {
                navigate(`/admin/client/details/${response.data.id}`)
                setAllertMessage('')
                setInputChecked(false);
              }, REDIRECT_DELAY);
            }
          })
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setAllertMessage(alertErrorSend);
        } else {
          console.error(error);
        }
      });
  };

  useEffect(() => {
    getUsers(1000, 0)
      .then((result) => {
        setAssistans(result.data.users
          .map(user => {
            return {
              value: `${user.firstName} ${user.lastName}`,
              id: user.id
            }
          }))
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          console.error('Ошибка 403: Доступ запрещен');
        } else {
          console.error('Произошла ошибка при получении пользователя:', error.message);
        }
      })
  }, []);

  //const alertErrorIncorrectText = <Alert severity="error">{t('alerts:error_words_lang_wrong_input')}</Alert>;
  // const alertErrorMail = <Alert severity="error">{t('alerts:error_format_email')}</Alert>;
  const alertErrorAccount = <Alert severity="error">{t('alerts:error_client_bank_account_type')}</Alert>;
  const alertErrorPesel = <Alert severity="error">{t('alerts:error_client_pesel')}</Alert>;
  const alertSucces = <Alert severity="success">{t('alerts:success_message_create_client')}</Alert>;
  const alertErrorSend = <Alert severity="error">{t('alerts:error_invoice_create')}</Alert>;
  // const alertErrorTel = <Alert severity="error">{t('alerts:error_phone_validation')}</Alert>;
  //const alertErrorIncorrectPostIndex = <Alert severity="error">{t('alerts:error_client_post_index_type')}</Alert>;

  useEffect(() => {
    const entries = Object.entries(errorMap);
    const clientErrors = CLIENT_STATES_ERRORS(t);

    setE(prevErrors => (
      entries.map((entry, index) => {
        const [key, value] = entry;
        const translatedKey = clientErrors[key] || key;
        const translatedValue = clientErrors[value] || value;
        return (
          <Alert key={index} severity="error">{translatedKey}: {translatedValue}</Alert>
        );
      })
    ));
  }, [
    errorMap,
    clientName,
    clientLastName,
    clientEmail,
    clientPhone,
    inputChecked,
    clientCity,
    clientPostIndex,
    clientPassport,
    clientAddress,
    clientDateOfBirth,
    clientCitizenship,
    clientRepresentativeCompany,
    t
  ]);

  return (
    <div className="relative bg-grayLightMainBg px-8">
      <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
        <div className="flex justify-between py-8 items-center ">
          <div className=" w-5/12  text-4xl ">
            {t('clients:create_client_capture')}
          </div>
          <div className=''>

            <Button className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" variant="outlined"
              onClick={() => {
                handleNavigate();
              }} >
              {t('button_back')}
            </Button>
            <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
              onClick={() => {
                onClickSendButtonHandler(t);
              }} >
              {t('button_add')}
            </Button>
          </div>
        </div>
      </div>
      <span key="e">{e}</span>
      <ClientForm
        showAssistentAssign={true}
        alertMessage={alertMessage}
        alertErrorAccount={alertErrorAccount}
        inputChecked={inputChecked}
        name={clientName}
        setName={setClientName}
        lastName={clientLastName}
        setLastName={setClientLastName}
        email={clientEmail}
        setEmail={setClientEmail}
        phone={clientPhone}
        setPhone={setClientPhone}
        citizenship={clientCitizenship}
        setCitizenship={setClientCitizenship}
        dateOfBirth={clientDateOfBirth}
        setDateOfBirth={setClientDateOfBirth}
        pesel={clientPesel}
        setPesel={setClientPesel}
        passport={clientPassport}
        setPassport={setClientPassport}
        setAllertMessage={setAllertMessage}
        inputValueIncubatorPaymentAccount={clientIncubatorBankAccounts}
        setInputValueIncubatorPaymentAccount={setClientIncubatorBankAccounts}
        inputValueClientPaymentAccount={clientBankAccounts}
        setInputValueClientPaymentAccount={setClientBankAccounts}
        representativeCompany={clientRepresentativeCompany}
        setRepresentativeCompany={setClientRepresentativeCompany}
        address={clientAddress}
        setAddress={setClientAddress}
        postIndex={clientPostIndex}
        setPostIndex={setClientPostIndex}
        city={clientCity}
        setCity={setClientCity}
        assistans={assistans}
        selectedAssistant={selectedAssistant}
        setSelectedAssistant={setSelectedAssistant}
      />
    </div>
  )
}
export default ClientCreatePage;
