import { Box } from '@mui/material';
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { COUNTRIES_LIST, REPRESENTATIVE_COMPANY_LIST, transformFromIdValueToValueLabel, validateEmail, validateNoneCyrillic, validatePesel, validatePhone } from "../../constants";
import ClientBankAccount from "../admin-page-components/clientsComponent/ClientBankAccount";
import { Combobox } from '../ui/combobox';
import { DatePicker } from '../ui/date-picker';
import { InputWithAlert } from '../ui/input-with-alert';
import { SelectWithAlert } from '../ui/select-with-alert';

export const ClientForm = (props) => {

  const { t } = useTranslation();
  const {
    alertMessage,
    alertErrorAccount,
    inputChecked,
    name,
    setName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    citizenship,
    setCitizenship,
    dateOfBirth,
    setDateOfBirth,
    pesel,
    setPesel,
    passport,
    setPassport,
    inputValueIncubatorPaymentAccount,
    setInputValueIncubatorPaymentAccount,
    inputValueClientPaymentAccount,
    setInputValueClientPaymentAccount,
    representativeCompany,
    setRepresentativeCompany,
    address,
    setAddress,
    postIndex,
    setPostIndex,
    city,
    setCity,
    assistans,
    selectedAssistant,
    setSelectedAssistant,
    showAssistentAssign

  } = props;

  return <div className='w-full'>
    <Box sx={{ padding: '0px 0px 10px 0px' }}>{alertMessage}</Box>
    <div className="w-full text-xl font-medium pb-6 ">{t('clients:create_client_main_block_capture')}</div>
    <div className='w-full flex justify-between'>
      <div className='w-[49%]'>
        <InputWithAlert
          data-testid="client_name"
          error={(inputChecked && (!name || !validateNoneCyrillic(name)))}
          errorMessage={!name ? t('empty_data_invalid') : t('format_data_invalid')}
          label={t('name') + "*"}
          placeholder={t('name') + "*"}
          value={name}
          onChange={(e) => { setName(e.target.value) }}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div>
      <div className='w-[49%]'>
        <InputWithAlert
          data-testid="client_last_name"
          error={(inputChecked && (!lastName || !validateNoneCyrillic(lastName)))}
          errorMessage={!lastName ? t('empty_data_invalid') : t('format_data_invalid')}
          label={t('last_name') + "*"}
          placeholder={t('last_name') + "*"}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div >
    </div>
    <div className='w-full flex justify-between'>
      <div className='w-[49%]'>
        <InputWithAlert
          data-testid="client_email"
          error={(inputChecked && (!email || !validateEmail(email)))}
          errorMessage={!email ? t('empty_data_invalid') : t('format_data_invalid')}
          label={"Email*"}
          placeholder={"Email*"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div >
      <div className='w-[49%]'>
        <InputWithAlert
          data-testid="client_phone"
          error={(inputChecked && (!phone || !validatePhone(phone)))}
          errorMessage={!phone ? t('empty_data_invalid') : t('format_data_invalid')}
          label={t('phone') + "*"}
          placeholder={t('phone') + "*"}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div >
    </div>
    <div className='w-full flex justify-between'>
      <div className='w-[49%]'>
        <Combobox
          data_testid="client_country"
          className={'w-full mr-2 '}
          label={t('country') + '*'}
          value={citizenship}
          placeholder={t('country')}
          error={inputChecked && (!citizenship)}
          errorMessage={t('empty_data_invalid')}
          options={COUNTRIES_LIST.map(transformFromIdValueToValueLabel).map((item) => {
            return {
              ...item,
              content: item,
              value: item.label,
            }
          })}
          onChange={(newValue) => {
            setCitizenship(newValue)
          }}
        />
      </div >
      <div className='w-[49%]'>
        <DatePicker date={dateOfBirth}
          data_testid={'client_bday'}
          setDate={(e) => setDateOfBirth(e)}
          error={inputChecked && !dateOfBirth}
          label={t('clients:create_client_placeholder_date_of_birth') + "*"}
          errorMessage={t('empty_data_invalid')}
          inputClassName={'h-12 text-base'}
        />
      </div >
    </div>
    <div className='w-full flex justify-between'>
      <div className='w-[49%]'>
        <InputWithAlert
          label={t('PESEL')}
          placeholder={t('PESEL')}
          value={pesel}
          onChange={(e) => setPesel(e.target.value)}
          error={inputChecked && pesel && !validatePesel(pesel)}
          errorMessage={t('format_data_invalid')}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div >
      <div className='w-[49%]'>
        <InputWithAlert
          error={(inputChecked && (!passport || !validateNoneCyrillic(passport)))}
          errorMessage={!passport ? t('empty_data_invalid') : t('format_data_invalid')}
          label={t('clients:create_client_placeholder_passport') + "*"}
          placeholder={t('clients:create_client_placeholder_passport') + "*"}
          value={passport}
          onChange={(e) => setPassport(e.target.value)}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div >
    </div>
    <div className='w-full flex justify-between'>
      <div className='w-[49%]'>
        <ClientBankAccount
          isIncubator={true}
          inputChecked={inputChecked}
          alertErrorAccount={alertErrorAccount}
          bankAccounts={inputValueIncubatorPaymentAccount}
          setBankAccounts={setInputValueIncubatorPaymentAccount}
        />
      </div >
      <div className='w-[49%]'>
        <ClientBankAccount
          isIncubator={false}
          inputChecked={inputChecked}
          alertErrorAccount={alertErrorAccount}
          bankAccounts={inputValueClientPaymentAccount}
          setBankAccounts={setInputValueClientPaymentAccount}
        />
      </div >
    </div>
    <div className='w-full pt-12'>
      <div className='w-full flex justify-between '>
        <div className='w-[49%]'>
          <SelectWithAlert
            label={t('clients:representative_company') + "*"}
            value={representativeCompany}
            onChangeValue={(e) => setRepresentativeCompany(e)}
            options={REPRESENTATIVE_COMPANY_LIST.map(transformFromIdValueToValueLabel)}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
        {showAssistentAssign && (
          <div className='w-[49%] pt-[2px]'>
            <Combobox
              className="min-h-12 mt-2  bg-white"
              placeholder={t('selected')}
              badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
              value={selectedAssistant}
              label={t('assistant')}
              readOnly={false}
              error={inputChecked && !selectedAssistant}
              errorMessage={t('empty_data_invalid')}
              onChange={(newSelected) => {
                setSelectedAssistant(newSelected)
              }}
              options={assistans.map(transformFromIdValueToValueLabel)}
            />
          </div>)}
      </div>
    </div >
    <div className='w-full pt-6 pb-16'>
      <div className="w-full text-xl font-medium pb-6 ">
        {t('address_title')}
      </div>
      <div className='w-[49%]'>
        <InputWithAlert
          error={(inputChecked && (!address || !validateNoneCyrillic(address)))}
          errorMessage={!address ? t('empty_data_invalid') : t('format_data_invalid')}
          label={t('address') + "*"}
          placeholder={t('address') + "*"}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div >
      <div className='w-[49%]'>
        <InputWithAlert
          error={(inputChecked && (!postIndex || !validateNoneCyrillic(postIndex)))}
          errorMessage={!postIndex ? t('empty_data_invalid') : t('format_data_invalid')}
          label={t('post_index') + "*"}
          placeholder="__-___"
          value={postIndex}
          onChange={(e) => setPostIndex(e.target.value)}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div >
      <div className='w-[49%]'>
        <InputWithAlert
          error={(inputChecked && (!city || !validateNoneCyrillic(city)))}
          errorMessage={!city ? t('empty_data_invalid') : t('format_data_invalid')}
          label={t('city') + "*"}
          placeholder={t('city') + "*"}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
      </div >
    </div>
  </div >
}
