import * as React from 'react';
import { useState, useEffect } from 'react';
import { FreelancerProfile } from './freelancer.profile';
import { MainInformation } from './main.information';
import { Button } from "@/components/ui/button"
import { v4 } from 'uuid';
import { OPTIONS_LANGUAGE, OPTIONS_LANGUAGE_LEVEL, EDUCATION_TYPE, OPTIONS_STUDY_FIELD, EXISTING_ABILITY_ID } from '@/constants';
import { updateFrilancerProfile } from '@/service/weexpertService';
import { MainInformationEdit } from './main.information.edit';
import { useTranslation } from 'react-i18next';
import { toast } from '../ui/use-toast';

export function ClientProfile(props) {

    const { t } = useTranslation();

    const { userData, id } = props;
    const { clientProfile } = userData;
    const { abilities = [] } = clientProfile || {};

    const [freelancerProfile, setFrilancerProfile] = useState(true);
    const [edit, setEdit] = useState(false);
    const [jobTitle, setJobTitle] = useState(userData && userData.clientProfile ? userData.clientProfile.currentJobTitle : 'TES');
    const [experience, setExperience] = useState(userData && userData.clientProfile ? userData.clientProfile.currentExperience : '');
    const [ldLink, setLdLink] = useState(userData && userData.clientProfile ? userData.clientProfile.linkedin : '');
    const [showSpecificSkills, setShowSpecificSkills] = useState(false);
    const [inputChecked, setInputChecked] = useState(false);
    const [interestedInProject, setInterestedInProject] = useState(userData && userData.clientProfile ? userData.clientProfile.interestedInProjects : false);

    const [maxHours, setMaxHours] = useState(userData && userData.clientProfile ? userData.clientProfile.availability : '0');
    const [rate, setRate] = useState(userData && userData.clientProfile ? userData.clientProfile.rate : '0');

    const [selectedSkills, setSelectedSkills] = useState(
        abilities
            .filter(e => e.abilityType == 'Skill')
            .map(e => {
                return {
                    id: e.ability.code ?? v4(),
                    label: e.ability.name,
                    value: e.ability.name
                }
            }) ?? []
    );
    const [selectedTools, setSelectedTools] = useState(
        abilities
            .filter(e => e.abilityType == 'ToolsAndTechnologies')
            .map(e => {
                return {
                    id: e.ability.code ?? v4(),
                    label: e.ability.name,
                    value: e.ability.name
                }
            }) ?? []
    );
    const [selectedProgrammingLanguages, setSelectedProgrammingLanguages] = useState(
        abilities
            .filter(e => e.abilityType == 'ProgrammingLanguage')
            .map(e => {
                return {
                    id: e.ability.code ?? v4(),
                    label: e.ability.name,
                    value: e.ability.name
                }
            }) ?? []
    );
    const [selectedFrameworks, setSelectedFrameworks] = useState(
        abilities
            .filter(e => e.abilityType == 'Frameworks')
            .map(e => {
                return {
                    id: e.ability.code ?? v4(),
                    label: e.ability.name,
                    value: e.ability.name
                }
            }) ?? []
    );
    const [languagesData, setLanguagesData] = useState(
        userData && userData.clientProfile && userData.clientProfile.languages
            ? userData.clientProfile.languages.map(e => {
                const a = {
                    id: e.id,
                    language: {
                        value: OPTIONS_LANGUAGE().filter(p => p.id == e.code)[0]?.id ?? v4(),
                        label: e.name
                    },
                    proficiency:
                        OPTIONS_LANGUAGE_LEVEL().filter(p => p.id == e.proficiency).map(s => {
                            return {
                                value: s.id,
                                label: s.value
                            }
                        })[0] ?? null,
                };
                return a;
            })
            : [{ id: 1, language: null, proficiency: null }]
    );

    const [selectedEducation, setSelectedEducation] = useState(
        userData && userData.clientProfile && userData.clientProfile.educationLevel
            ? EDUCATION_TYPE()
                .filter(q => q.id == clientProfile.educationLevel)
                .map(e => {
                    return {
                        value: e.id,
                        label: e.value
                    };
                })[0] ?? null
            : null
    );
    const [selectedStudy, setSelectedStudy] = useState(
        userData && userData.clientProfile && userData.clientProfile.fieldOfStudy
            ? OPTIONS_STUDY_FIELD()
                .filter(q => q.id == clientProfile.fieldOfStudy)
                .map(e => {
                    return {
                        value: e.id,
                        label: e.value
                    };
                })[0] ?? null
            : null
    );

    useEffect(() => {
        if (!freelancerProfile) {
            setEdit(false)
        }
    }, [freelancerProfile])

    const updateFrilancer = () => {
        setInputChecked(true);
        if (!jobTitle) {
            return
        }
        if (!experience) {
            return
        }
        if (!selectedEducation) {
            return
        }
        if (!selectedStudy) {
            return
        }

        if (!selectedSkills) {
            return
        }
        if (!selectedSkills) {
            return
        }
        if (!languagesData.length || !languagesData) {
            toast({
                title: t('survey:label_language').replace('*', ''),
                description: t('empty_data_invalid'),
                variant: "destructive"
            })
            return
        }
        if (!languagesData.length || !languagesData) {
            toast({
                title: t('survey:label_language').replace('*', ''),
                description: t('empty_data_invalid'),
                variant: "destructive"
            })
            return
        }

        const uniqueValues = new Set(languagesData.map(v => v.language.value));
        if (uniqueValues.size < languagesData.length) {
            toast({
                title: t('survey:error_duplicate_language'),
                variant: "destructive"
            })
            return
        }
        updateFrilancerProfile(
            renameKeys(selectedSkills),
            renameKeys(selectedFrameworks),
            renameKeys(selectedTools),
            renameKeys(selectedProgrammingLanguages),
            selectedEducation.value,
            selectedStudy.value,
            languagesData.map(e => {
                return {
                    code: e.language.value,
                    proficiency: e.proficiency.value
                }
            }),
            interestedInProject,
            '' + rate,//rate
            '' + maxHours,//aviability
            jobTitle,
            '' + experience,
            ldLink === '' ? null : ldLink,
        )
            .then(response => {
                setEdit(false)
                toast({
                    title: t('alerts:succes')
                })
            })
            .catch(error => {
                toast({
                    title: t('alerts:error'),
                    variant: 'destructive'
                })
            });
    }

    function renameKeys(array) {
        return array.map(obj => {
            return {
                name: obj.label,
                code: EXISTING_ABILITY_ID.includes(obj.id) ? obj.id : null // meaning if we have value == label it is custom created and backend expect it to null as code value for the custom created element
            }
        });
    }

    return (
        <>

            <div className='w-6/12 pt-10 pl-8'>
                {id ? (<div className='w-72 text-4xl'>
                    {`${userData && userData.clientProfile.client.firstName} ${userData && userData.clientProfile.client.lastName}`}
                </div>) : (<div className='w-72 text-4xl'>
                    {t("users:account_title")}
                </div>)}

                <div className='w-full relative flex flex-row justify-between items-end'>
                    <div className='w-72 '>
                        <div className='pt-8 flex justify-between items-center'>
                            <Button className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none ${freelancerProfile ? 'opacity-70' : ''}`}
                                onClick={() => setFrilancerProfile(false)}
                            >
                                {t("users:main_information")}
                            </Button>
                            <Button className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none ${freelancerProfile ? '' : 'opacity-70'}`}
                                onClick={() => setFrilancerProfile(true)}
                            >
                                {t("users:freelancer_profile")}
                            </Button>
                        </div>
                    </div>
                    <div>
                        {!edit && !id ? (
                            <Button onClick={() => { freelancerProfile && setEdit(true) }} className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none  ${freelancerProfile ? 'text-blueLink' : 'opacity-70'}`}
                            >
                                {t("button_change")}
                            </Button>
                        ) : (<div>
                            <Button onClick={() => setEdit(false)} className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none  ${freelancerProfile ? 'text-blueLink' : 'opacity-70'}`}
                            >
                                {t("button_back")}
                            </Button>
                            <Button onClick={() => { updateFrilancer() }} >
                                {t("button_save")}
                            </Button></div>)}
                    </div>
                </div>
                <div className="w-72 relative mt-2 h-1 bg-blueBorder">
                    <div className={`absolute w-6/12 h-full ${freelancerProfile ? 'right-0' : 'left-0'} top-0 bg-blueLink`}></div>
                </div>
                {
                    freelancerProfile && userData ?
                        <FreelancerProfile
                            userData={userData}
                            job={jobTitle}
                            setJob={setJobTitle}
                            experience={experience}
                            setExperience={setExperience}
                            ldLink={ldLink}
                            setLdLink={setLdLink}
                            abilities={abilities}
                            setShowSpecificSkills={setShowSpecificSkills}
                            selectedSkills={selectedSkills}
                            setSelectedSkills={setSelectedSkills}
                            selectedTools={selectedTools}
                            setSelectedTools={setSelectedTools}
                            selectedProgrammingLanguages={selectedProgrammingLanguages}
                            setSelectedProgrammingLanguages={setSelectedProgrammingLanguages}
                            selectedFrameworks={selectedFrameworks}
                            setSelectedFrameworks={setSelectedFrameworks}
                            clientProfile={clientProfile}
                            languagesData={languagesData}
                            setLanguagesData={setLanguagesData}
                            inputChecked={inputChecked}
                            setInputChecked={setInputChecked}
                            selectedEducation={selectedEducation}
                            setSelectedEducation={setSelectedEducation}
                            selectedStudy={selectedStudy}
                            setSelectedStudy={setSelectedStudy}
                            checked={interestedInProject}
                            setChecked={setInterestedInProject}
                            currentRate={rate}
                            setCurrentRate={setRate}
                            maxHours={maxHours}
                            setMaxHours={setMaxHours}
                            id={id}
                            edit={edit} />
                        :
                        (id ? <MainInformationEdit userData={userData} /> :
                            <MainInformation userData={userData} />)

                }
            </div>
        </>
    );

}
