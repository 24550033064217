import { Alert, Box, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { COUNTRIES_LIST, REDIRECT_DELAY, validateOnlyLatin, validatePostIndex, validateEmail, validatePhone, validateNoneCyrillic, transformFromIdValueToValueLabel } from "../constants";
import { changeClient, getOneClient } from '../service/weexpertService';
import { grayButton } from "../styles";
import { ClientForm } from "../components/clients/ClientForm";
import { useTranslation } from 'react-i18next';
import { CLIENT_STATES_ERRORS } from "../constants";
import dayjs from "dayjs";
import { Button } from '@/components/ui/button';
import { format } from "date-fns";

export const ClientEditPage = () => {

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [inputChecked, setInputChecked] = useState(false);
  const [itemData, setItemData] = useState({});
  const [alertMessage, setAllertMessage] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState(''); //email
  const [clientCitizenship, setClientCitizenship] = useState('');
  const [clientPesel, setClientPesel] = useState(null);
  const [clientIncubatorBankAccounts, setClientIncubatorBankAccounts] = useState([]);
  const [clientBankAccounts, setClientBankAccounts] = useState([]);
  const [clientLastName, setClientLastName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientDateOfBirth, setClientDateOfBirth] = useState('');
  const [clientPassport, setClientPassport] = useState('');
  const [clientStatus, setClientStatus] = useState('');

  const [clientPostIndex, setClientPostIndex] = useState('');
  const [clientCity, setClientCity] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [clientRepresentativeCompany, setRepresentativeCompany] = useState('');

  const [errorMap, setErrorMap] = useState({});
  const [e, setE] = useState('');

  useEffect(() => {
    getOneClient(id)
      .then(res => {
        setItemData(res.data);
        setClientName(res.data.firstName ? res.data.firstName : '');
        setClientLastName(res.data.lastName ? res.data.lastName : '');
        setClientEmail(res.data.email ? res.data.email : '');
        setClientPhone(res.data.phone ? res.data.phone : '');
        const default_country = res.data.citizenship ?
          (COUNTRIES_LIST.filter(e => e.id == res.data.citizenship).length > 0 ?
            COUNTRIES_LIST.filter(e => e.id == res.data.citizenship).map(transformFromIdValueToValueLabel).map(e => {
              return {
                content: {
                  value: e.value,
                  label: "Polska"
                },
                ...e,
                value: e.label
              }
            })[0] :
            {
              content: {
                value: "pl",
                label: "Polska"
              },
              value: "Polska",
              label: "Polska"
            }) :
          {
            content: {
              value: "pl",
              label: "Polska"
            },
            value: "Polska",
            label: "Polska"

          };
        setClientCitizenship(default_country);
        setClientDateOfBirth(new Date(res.data.dateOfBirth));
        setClientPesel(res.data.pesel ? res.data.pesel : '');
        setClientPassport(res.data.passport ? res.data.passport : '');
        const bankAccounts = res.data.bankAccounts
        const incubatorAccounts = bankAccounts.filter(account => account.accountType === 'INCUBATOR');
        const clientAccounts = bankAccounts.filter(account => account.accountType === 'CLIENT');
        setClientIncubatorBankAccounts(incubatorAccounts);
        setClientBankAccounts(clientAccounts);
        setClientPostIndex(res.data.postIndex ? res.data.postIndex : '');
        setClientCity(res.data.city ? res.data.city : '');
        setClientAddress(res.data.street ? res.data.street : '');
        setClientStatus(res.data.isActive ? res.data.isActive : '');
        setRepresentativeCompany(res.data.representativeCompany ? res.data.representativeCompany : '')
      }).then(() => {
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          console.error("Bad Request:", error.response.data);
          if (error.response.data && error.response.data.client === "Not found") {
            navigate('/404')
          }
          if (error.response.data && error.response.data.message === "clinet id is not a number") {
            navigate('/404')
          }
        } else {
          console.error("Error:", error);
        }
      });
  }, [id]);

  const setAndValidateEmail = (newValue) => {
    const isValid = validateEmail(newValue);
    if (!isValid && newValue.length !== 0) {
      setErrorMap(Object.assign(errorMap, { email: "FOMAT_ERROR" }))
    } else {
      const { email, ...newErrorMap } = errorMap
      setErrorMap(newErrorMap)
    }
    setClientEmail(newValue)
  };

  const setAndValidatePhone = (newValue) => {
    const isValid = validatePhone(newValue)
    if (!isValid && newValue.length !== 0) {
      setErrorMap(Object.assign(errorMap, { phone: "FOMAT_ERROR" }))
    }
    else {
      const { phone, ...newErrorMap } = errorMap
      setErrorMap(newErrorMap)
    }
    setClientPhone(newValue)
  };

  const setAndValidateName = (newValue) => {
    const isValid = validateOnlyLatin(newValue)
    if (!isValid && newValue.length !== 0) {
      setErrorMap(Object.assign(errorMap, { name: "FOMAT_ERROR" }))
    }
    else {
      const { name, ...newErrorMap } = errorMap
      setErrorMap(newErrorMap)
    }
    setClientName(newValue)
  };

  const setAndValidateSurname = (newValue) => {
    const isValid = validateOnlyLatin(newValue)
    if (!isValid && newValue.length !== 0) {
      setErrorMap(Object.assign(errorMap, { lastName: "FOMAT_ERROR" }))
    }
    else {
      const { lastName, ...newErrorMap } = errorMap
      setErrorMap(newErrorMap)
    }
    setClientLastName(newValue)
  };

  const setAndValidateInputValueDateOfBirth = (newValue) => {
    if (!dayjs(newValue).isValid()) {
      setErrorMap(Object.assign(errorMap, { dateOfBirth: "FOMAT_ERROR" }))
    }
    else {
      const { dateOfBirth, ...newErrorMap } = errorMap
      setErrorMap(newErrorMap)
    }
    setClientDateOfBirth(newValue)
  };
  const onClickSendButtonHandler = () => {

    setInputChecked(true)
    if ((!clientEmail || !validateEmail(clientEmail))) {
      return;
    }
    if ((!clientPassport)) {
      return;
    }

    if (!clientName || !validateNoneCyrillic(clientName)) {
      return
    }
    if ((!clientLastName || !validateNoneCyrillic(clientLastName))) {
      return
    }
    if (
      (!clientPhone || !validatePhone(clientPhone)) ||
      (!clientCitizenship) ||
      (!clientCity || !validateNoneCyrillic(clientCity)) ||
      (!clientPostIndex) ||
      (!clientRepresentativeCompany) ||
      (!clientAddress || !validateNoneCyrillic(clientAddress)) ||
      (!clientPhone || !validateNoneCyrillic(clientPhone)) ||
      (!clientDateOfBirth)
    ) {
      return;
    }

    changeClient(
      clientName,
      clientLastName,
      clientEmail,
      clientPhone,
      clientPesel,
      clientIncubatorBankAccounts.filter(e => e.account !== ''),
      clientBankAccounts.filter(e => e.account !== ''),
      '',
      clientCity,
      clientPostIndex,
      clientPassport,
      clientAddress,
      '',
      clientStatus ? clientStatus : false,
      '',
      format(clientDateOfBirth, 'yyyy-MM-dd'),
      clientCitizenship.content.value,
      id,
      clientRepresentativeCompany
    )
      .then((response) => {
        if (response.status === 200) {
          setAllertMessage(alertSuccess);
          setTimeout(() => {
            navigate('/admin/clients');
          }, REDIRECT_DELAY);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400)
          setAllertMessage(alertSendingError);
        console.error(error);
      });
  };

  //const alertErrorIncorrectText = <Alert severity="error">{t('alerts:error_words_lang_wrong_input')}</Alert>;
  //const alertErrorMail = <Alert severity="error">{t('alerts:error_format_email')}</Alert>;
  const alertSendingError = <Alert severity="error">{t('alerts:error_phone_validation')}</Alert>;
  const alertSuccess = <Alert severity="success">{t('alerts:success_message_change_client')}</Alert>;
  const alertErrorAccount = <Alert severity="error">{t('alerts:error_client_bank_account_type')}</Alert>;
  const alertErrorPesel = <Alert severity="error">{t('alerts:error_client_pesel')}</Alert>;
  //const alertErrorTel = <Alert severity="error">{t('alerts:error_phone_validation')}</Alert>;
  //const alertErrorSend = <Alert severity="error">{t('alerts:error_invoice_create')}</Alert>;
  //const alertErrorIncorrectPostIndex = <Alert severity="error">{t('alerts:error_client_post_index_type')}</Alert>;

  useEffect(() => {
    const entries = Object.entries(errorMap);
    const clientErrors = CLIENT_STATES_ERRORS(t);

    setE(prevErrors => (
      entries.map((entry, index) => {
        const [key, value] = entry;
        const translatedKey = clientErrors[key] || key;
        const translatedValue = clientErrors[value] || value;
        return (
          <Alert key={index} severity="error">{translatedKey}: {translatedValue}</Alert>
        );
      })
    ));
  }, [
    errorMap,
    clientName,
    clientLastName,
    clientEmail,
    clientPhone,
    inputChecked,
    clientCity,
    clientPostIndex,
    clientPassport,
    clientAddress,
    clientDateOfBirth,
    clientCitizenship,
    clientRepresentativeCompany,
    t
  ]);

  return (
    <Box>
      {loading ? (
        <Box sx={{ width: "100%", height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="grey" />
          </Stack>
        </Box>
      ) : (
        <div className="relative bg-grayLightMainBg px-8">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between py-8 items-center ">
              <div className=" w-5/12  text-4xl ">
                {t('clients:edit_client_capture')}
              </div>
              <Button className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
                onClickSendButtonHandler();
              }}>{t('button_save')}
              </Button>
            </div>
          </div>
          <div className='w-full py-8'>
            <Typography variant='h7'>{t('client')}</Typography>
            <div className=" text-2xl ">
              {itemData.firstName ? (itemData.firstName + ' ' + itemData.lastName) : ''}
            </div>
          </div>
          <span key="e">{e}</span>
          <ClientForm
            alertMessage={alertMessage}
            alertErrorAccount={alertErrorAccount}
            inputChecked={inputChecked}
            name={clientName}
            setName={setAndValidateName}
            lastName={clientLastName}
            setLastName={setAndValidateSurname}
            email={clientEmail}
            setEmail={setAndValidateEmail}
            phone={clientPhone}
            setPhone={setAndValidatePhone}
            citizenship={clientCitizenship}
            setCitizenship={setClientCitizenship}
            dateOfBirth={clientDateOfBirth}
            setDateOfBirth={setAndValidateInputValueDateOfBirth}
            pesel={clientPesel}
            setPesel={setClientPesel}
            passport={clientPassport}
            setPassport={setClientPassport}
            inputValueIncubatorPaymentAccount={clientIncubatorBankAccounts}
            setInputValueIncubatorPaymentAccount={setClientIncubatorBankAccounts}
            inputValueClientPaymentAccount={clientBankAccounts}
            setInputValueClientPaymentAccount={setClientBankAccounts}
            representativeCompany={clientRepresentativeCompany}
            setRepresentativeCompany={setRepresentativeCompany}
            address={clientAddress}
            setAddress={setClientAddress}
            postIndex={clientPostIndex}
            setPostIndex={setClientPostIndex}
            city={clientCity}
            setCity={setClientCity}
          />
        </div>)}
    </Box>
  )

}
export default ClientEditPage
