import { BaseDialog } from "./dialog"
import { useState } from "react"
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { Combobox } from "../ui/combobox";
import { INVOICE_TYPE ,ROLES,INVOICE_TYPE_FOR_CLIENT} from "@/constants";
import { useNavigate } from 'react-router-dom';
import { cloneInvoice } from "@/service/weexpertService";
import { DatePicker } from "../ui/date-picker";
import { toast } from "../ui/use-toast";

export default function InvoiceClone({ id, invoiceId, className, icon,role }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [issueDate, setIssueDate] = useState(new Date());
  const [invoiceType, setInvoiceType] = useState("");
  const onClickSendButtonHandler = () => {
    cloneInvoice(id, issueDate, invoiceType)
      .then((response) => {
        const responseData = response.data;
        toast({
          title: "Invoice was cloned",
          description: "You can edit it if you need",
        })
        navigate(`/admin/invoices/details/edit/${responseData.invoices.id}`)
      })
      .catch((error) => {
        toast({
          title: "Somehting went wrong",
          description: "Can not clone invoice",
          variant: "destructive"
        })
        if (error.response && error.response.status === 400) {
          console.log("400-----> " + error)
        } else {
          console.error(error);
        }
      });
  };

  return <BaseDialog
    dialogTitle={`${t('invoices:button_clone') + ': '}   ${invoiceId}`}
    buttonText={t("invoices:button_clone")}
    open={open}
    setOpen={setOpen}
    className={className}
    icon={icon}
  >
    <div className="w-[500px] flex flex-col items-end ">
      <div className="w-full py-4 bg-grayLightMainBg">
        <Combobox
          className={'w-full mr-2'}
          value={invoiceType}
          label={t("invoices:invoice_type")}
          placeholder={t("invoices:invoice_type")}
          options={ role !== ROLES.CLIENT ? INVOICE_TYPE(t).map(e => {
            return {
              value: e.id,
              label: e.value
            }
          }) : INVOICE_TYPE_FOR_CLIENT(t).map(e => {
            return {
              value: e.id,
              label: e.value
            }
          })}
          onChange={(newValue) => {
            setInvoiceType(newValue.value)
          }}
        />
        <DatePicker
          label={t("invoices:date_of_creation")}
          date={issueDate}
          setDate={(date) => {
            if (!date) return
            setIssueDate(date)
          }
          }
          inputClassName={'h-12 text-base mb-4'}
        />
      </div>
      <Button onClick={() => { onClickSendButtonHandler(); }} >
        {t("invoices:button_clone")}
      </Button>
    </div>
  </BaseDialog>
}
