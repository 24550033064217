import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { green, orange, } from "@mui/material/colors";
import { Box, } from '@mui/material';
import React from 'react'

//colors

export const gray = '#1D1C38';
export const grayLight = '#30345c';
export const grayGhost = '#c0c4d2';
export const grayGhost400 = '#585d81';
export const grayLightGhost = '#737996';
export const activeGreen = green[500];
const orangeInProcess = orange[400];

//statuses & cheaps

export const styleForStatusActive = {
    width: '110px',
    textAlign: 'center',
    padding: '4px 6px 4px 6px',
    borderRadius: '16px',
    background: activeGreen,
    color: '#ffff'
};
export const styleForStatusInactive = {
    width: '110px',
    textAlign: 'center',
    padding: '4px 6px 4px 6px',
    borderRadius: '16px',
    background: grayGhost,
    color: gray
};

export const icon = (<CheckBoxOutlineBlankIcon fontSize="small" />);
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const statusInProgress = (<Box sx={{ color: orangeInProcess }}>На рассмотрении</Box>)
export const statusComplete = (<Box sx={{ color: 'green' }}>Одобрено</Box>)

export function formatDateString(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

//buttons



export const whiteButtonWithLightHoverOpasity = {
    width: '100%',
    cursor: 'pointer',
    color: `${gray}`,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    textTransform: 'none',
    margin: '0 auto',
    opacity: 0.6, "&:hover": { opacity: 1 }
}
export const grayButton = {
    background: gray,
    width: '250px',
    height: '42px',
    color: '#fff',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

export const grayIconButtonNoWidth = {
    background: gray,
    color: '#fff',

    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

export const dotsButton = {
    width: '60px',
    height: 42,
    margin: '0px 0px 0px 0px',
    background: '#fff',
    color: gray,
    "&:hover": {
        borderRadius: '10px',
        color: gray,
        background: grayGhost
    },
};

export const whiteButtonWithHover = {
    width: '200px',
    display: 'flex',
    fontSize: '12px',
    justifyContent: 'start',
    alignItems: 'base-line',
    height: 42,
    margin: '0px 0px 0px 0px',
    padding: '0px 0px 0px 10px',
    background: '#fff',
    color: gray,
    boxShadow: 'none',
    opacity: 0.6,
    "&:hover": {
        color: gray,
        opacity: 1,
        boxShadow: 'none',
    },
};
export const whiteButtonWithBorder = {
    border: `1px solid ${gray}`,
    width: '40%',
    height: 42,
    margin: '0px 0px 0px 30px',
    color: gray,
    "&:hover": {
        border: `1px solid ${grayLight}`,
        color: gray,
        background: grayGhost
    },
};

export const filterButton = {
    background: 'rgba(29, 28, 43, 0.12)',
    color: gray,
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

//covers

export const mainFlexCoverSpaseBetween = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

}
export const mainFlexCoverCenter100vh = {
    width: '100%',
    height: '100vh',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
};

export const graylinkNoneDecotation = {
    textDecoration: 'none', color: gray
}

//inputs

export const inputStyles = {
    margin: '0px 0px 0px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

export const activeInputStylesForPosition = {
    fontSize: '12px',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: grayLight,
      },
      '&.Mui-focused fieldset': {
        borderColor: gray,
      },
      underline: {
        '&::placeholder': {
          color: 'purple',
          opacity: 1,
        },
      },
    },
    '& label.Mui-focused': {
      color: gray,
    },
  
  };

  export const inputStylesForPosition = {

    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: grayLight,
      },
      '&.Mui-focused fieldset': {
        borderColor: gray,
      },
      '& input': {
        fontSize: '12px',
        '&::placeholder': {
          fontSize: '12px',
        },
      },
      underline: {
        '&::placeholder': {
          color: 'red',
          opacity: 1,
        }
      },
      '&.MuiInputBase-multiline': {
  
        fontSize: '12px',
      },
    },
  
    '& .MuiInputLabel-root.Mui-focused': {
      fontSize: '12px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px',
    },
    '& label.Mui-focused': {
      color: grayLightGhost,
      fontSize: '12px',
    },
  
  };

//checkboxes

export const checkboxColorStyle = {
    color: 'grey',
    '&.Mui-checked': {
        color: gray,
    },
};




//cells

export const tableCellPosition = {

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '20px 0px 20px 0px',
    margin: '0px',
    cursor: 'pointer',
}

//popups

export const popupPositionStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: `1px solid ${gray}`,
    boxShadow: 0,
    p: 4,
};

//dropdown blocks

export const accountDropDown = {
    position: 'absolute',
    width: '180px',
    left: '-16px',
    top: '43px',
    padding: '20px',
    zIndex: '10',
    background: '#fff',
    boxShadow: ' -1px 1px 14px 0px rgba(0,0,0,0.75)',
    color: `${gray}`,
    borderRadius: '10px',
    textAlign: 'center'
}
export const headerDropDownMenu = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    background: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '-1px 1px 14px 0px rgba(0,0,0,0.75)',
    position: 'absolute',
    right: '0px',
    top: '80px',
    backgroundColor: '#fff',
    zIndex: 10
}
export const showDropDown = {
    display: 'block',
    padding: '10px 0px 0px 0px',
    width: '120px',
    position: 'absolute',
    top:'24px',
    right:'90px',
    margin: 0,
    borderRadius: '10px',
    background:'rgba(29, 28, 56, 1)',
    color: '#fff'
  };

export const cellPadding = {
    cursor: 'pointer',
    padding: '20px 0px 20px 12px'
};

// sticky headers

export const stickyCoverHeader = {
    width: '100%',
    position: 'sticky',
    zIndex: 4,
    top: '61px',
    background: '#fff',
    padding: '32px 0px 20px 0px',
    marginLeft: '0px',
}

export const styleLabelButtonSelected = (content_content) => {
    return {
        '&:hover::after': {
            content: `"${content_content}"`,
            display: 'block',
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '5px 10px 5px 10px',
            borderRadius: '5px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
            top: '80px',
            zIndex: 3
        }
    }
}
