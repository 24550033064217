import React, { useEffect, useState } from "react";
import AppBarForLogin from "../components/app-bar-for-main-login-pages/AppBarForLogin";
import '../scssStyles/SurveyStep5.scss';
import { whiteButtonWithBorder } from "../styles";
import { getUser, surveyStep5 } from "../service/weexpertService";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@/components/ui/switch"
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { Checkbox } from "@/components/ui/checkbox"
import { useQuery, useQueryClient } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { toast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";
import { SelectWithAlert } from "@/components/ui/select-with-alert";
import { AVAILABILITY_HOURS } from "@/constants";


export const SurveyStep5 = (props) => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { data: userData, isLoading: loading } = useQuery({ queryKey: ['userMeProfile'], queryFn: getUser, retry: false, refetchOnWindowFocus: false })
    const { back } = props
    const [interestedInProject, setInterestedInProject] = useState(false);
    const [maxHours, setMaxHours] = useState();
    const [currentRate, setCurrentRate] = useState(0);
    const [concent, setConcent] = useState(false);
    const queryClient = useQueryClient();
    useEffect(() => {
        if (!loading) {
            const { clientProfile } = userData.data;
            setInterestedInProject(clientProfile.interestedInProjects);
            setMaxHours(clientProfile.availability);
            setCurrentRate(clientProfile.rate);
            setConcent(clientProfile.concent);
        }
    }, [userData]);
    const handleChangeAgree = () => {
        setConcent(!concent)
    }

    const handleRateChange = (event) => {
        const value = event.target.value;
        if (value === '' || (/^\d+$/.test(value) && parseInt(value) >= 1 && parseInt(value) <= 500)) {
            setCurrentRate(value);
        }
    };

    const onSave = () => {
        if (!concent) {
            toast({
                title: t('survey:error_agree'),
                variant: "destructive"
            })
            return
        }
        surveyStep5(concent, currentRate ? currentRate : null, maxHours ? maxHours : null, interestedInProject)
            .then(response => {
                queryClient.invalidateQueries({ queryKey: ['userMeProfile', 'userMe'] })
                window.location.reload(false);
                navigate('/')
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    console.error('Ошибка 403: Доступ запрещен');
                } else {
                    console.error('Произошла ошибка при получении пользователя:', error.message);
                }
            });
    }

    if (loading) {
        return <Loader />
    }
    return (
        <>
            <div className={'container'}>
                <AppBarForLogin isLogined={true} userData={userData.data} />
                <div className="w-full  pt-24 pb-28" >
                    <div className="w-2/5 text-3xl font-semibold  pb-6">
                        {t('survey:step_5_capture')}
                    </div>
                    <div className="w-2/5 flex justify-between">
                        <div className="w-9/12  text-base">
                            {t('survey:step_5_description')}
                        </div>
                        <div className="mr-8 flex flex-row justify-center items-center">
                            <Switch
                                onClick={() => { setInterestedInProject(!interestedInProject);  }}
                                checked={interestedInProject}
                            />
                            <div className="pl-4">{interestedInProject ? t('survey:switch_yes') : t('survey:switch_no')}</div>
                        </div>
                    </div>
                    {interestedInProject && (
                        <div className="w-1/3 my-6">
                            <SelectWithAlert
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                label={t('survey:label_availability')}
                                placeholder={t('survey:placeholder_availability')}
                                value={maxHours}
                                onChangeValue={(e) => setMaxHours(e)}
                                options={
                                    (() => {
                                        const options = AVAILABILITY_HOURS(t);

                                        return options.some(e => e.value == maxHours) ? options : (
                                            maxHours ? options.concat({ value: maxHours, label: maxHours }) : options
                                        )
                                    })()
                                }
                            />
                            <InputWithAlert
                                label={t('survey:label_rate')}
                                errorMessage={t('survey:error_empty')}
                                value={currentRate}
                                onChange={handleRateChange}
                                placeholder={t('survey:placeholder_rate')}
                                inputClassName={'h-12 my-2 bg-white'}
                            />
                        </div>
                    )}
                    <div className="w-full flex  justify-start items-center pt-4">
                        <Checkbox className='flex flex-row items-center justify-center py-0'
                            checked={concent}
                            onCheckedChange={() => handleChangeAgree()}
                            aria-label="Select all"
                        />
                        <div className="pl-4">{t("survey:agreement_1")}  <Link to='/client/zgoda' target="_blank" className="underline">{t("survey:agreement_2")} </Link></div>

                    </div>
                </div>
                <Progress value={100} />
                <div className=" flex flex-row justify-between	 pt-6">
                    <Button sx={{ ...whiteButtonWithBorder, width: '152px', margin: 0 }} onClick={() => { back() }}>{t('survey:button_back')}</Button>
                    <Button className='w-36' onClick={() => onSave()}>{t('survey:button_go_to_platform')}</Button>
                </div>
            </div>
        </>
    );
}
