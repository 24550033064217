import ClientAssignCounterpartyPopup from '@/components/popups/client.assign.users.popup';
import ClientBlockPopup from '@/components/popups/client.block.popup';
import ClientRestorePasswordPopup from '@/components/popups/client.password-restore.popup';
import { Button } from '@/components/ui/button';
import { InputWithAlert } from '@/components/ui/input-with-alert';
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { ALLOWED_ASSIGN_CLIENT, ALLOWED_LINK_RESTORE, COUNTRIES_LIST } from "../constants";
import { getClientLinkRestore, getOneClient } from '../service/weexpertService';
import { toast } from '@/components/ui/use-toast';

export const ClientViewPage = (props) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({});
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);

  const handleRefreshData = () => {
    setRefreshData(prevRefreshData => !prevRefreshData); //update data list
  };

  const handleNavigateToEditClient = (id) => {
    navigate(`/admin/client/details/edit/${id}`);
  };

  useEffect(() => {
    if (id) {
      getOneClient(id)
        .then(res => {
          setItemData(res.data);
        }).then(() => {
          setLoading(false);
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            console.error("Bad Request:", error.response.data);
            if (error.response.data && error.response.data.client === "Not found") {
              navigate('/404')
            }
            if (error.response.data && error.response.data.message === "clinet id is not a number") {
              navigate('/404')
            }
          } else {
            console.error("Error:", error);
          }
        });
    }

  }, [id, refreshData]);

  const default_citizenship = itemData.citizenship ? (COUNTRIES_LIST.filter(e => e.id == itemData.citizenship).length > 0 ? COUNTRIES_LIST.filter(e => e.id == itemData.citizenship)[0] :
    {
      id: "pl",
      value: "Polska"
    }) :
    {
      id: "pl",
      value: "Polska"
    };

  const date = new Date(itemData.createdAt);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;
  const dateBirth = new Date(itemData.dateOfBirth);
  const d = String(dateBirth.getDate()).padStart(2, '0');
  const m = String(dateBirth.getMonth() + 1).padStart(2, '0');
  const y = dateBirth.getFullYear();
  const formattedDateOfBirth = `${d}/${m}/${y}`;
  const [incubatorAccount, setIncubatorAccount] = useState(null);
  const [clientAccount, setClientAccount] = useState(null);

  useEffect(() => {
    if (itemData && itemData.bankAccounts) {
      const incubatorAccounts = itemData.bankAccounts.filter(account => account.accountType === 'INCUBATOR');
      const clientAccounts = itemData.bankAccounts.filter(account => account.accountType === 'CLIENT');
      setIncubatorAccount(incubatorAccounts.map((item) => (
        <div key={item.id}>

          <InputWithAlert
            label={t("clients:bank_account_inqubator")}
            value={item.account === '' ? '' : `${item.account}  ${item.currency}`}
            readOnly={true}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
      )));
      setClientAccount(clientAccounts.map((item) => (
        <div key={item.id}>
          <InputWithAlert
            label={t("clients:bank_account_client")}
            value={item.account === '' ? '' : `${item.account}  ${item.currency}`}
            readOnly={true}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
      )));
    }
  }, [itemData]);

  const canAssign = ALLOWED_ASSIGN_CLIENT.includes(props.userData.roles);
  const canSeeLink = ALLOWED_LINK_RESTORE.includes(props.userData.roles);
  return (
    <>
      {loading ? (
        'loading'
      ) : (
        <div className="relative bg-grayLightMainBg px-8">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between py-8 items-center ">
              <div className=" w-5/12  text-4xl ">
                {t('clients:details_client_capture')}
              </div>
              <div className='w-6/12 flex justify-end'>
                {canSeeLink && (
                  <Button className=" text-blueText mr-2 bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
                    getClientLinkRestore(id).
                      then(res => {
                        toast({
                          title: 'Copied',
                        })
                        console.log(res)
                        navigator.clipboard.writeText(res.data)
                      })
                      .catch(() => {
                        toast({
                          title: 'Something went wrong',
                          variant: "destructive"
                        })
                      })
                  }}>
                    LINK
                  </Button>
                )}
                {canAssign && (
                  <>
                    <ClientBlockPopup
                      client={itemData}
                      userData={props.userData}
                      handleRefreshData={handleRefreshData}
                    />
                    <ClientAssignCounterpartyPopup
                      id={id}
                      handleRefreshData={handleRefreshData}
                    />
                    <ClientRestorePasswordPopup clientIds={[itemData.id]} />
                  </>
                )}
                <Button className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
                  handleNavigateToEditClient(id);
                }}>
                  {t('button_change')}
                </Button>
              </div>
            </div>
          </div>
          <div className='w-full py-8'>
            <div className=" text-2xl ">
              {itemData && itemData.firstName + ' ' + itemData.lastName}
            </div>
          </div>
          <div className=''>
            <div className='w-full'>
              <div className="w-full text-xl font-medium pb-6 ">
                {t('clients:create_client_main_block_capture')}
              </div>
              <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                  <InputWithAlert
                    label={t('name')}
                    value={itemData.firstName}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label='Email'
                    value={itemData.email}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t('clients:create_client_placeholder_sitizen')}
                    value={default_citizenship.value}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label='PESEL'
                    value={itemData.pesel ? itemData.pesel : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  {incubatorAccount}
                </div>
                <div className='w-[49%]'>
                  <InputWithAlert
                    label={t('last_name')}
                    value={itemData.lastName}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t('phone')}
                    value={itemData.phone}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t('clients:create_client_placeholder_date_of_birth')}
                    value={formattedDateOfBirth}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t('clients:create_client_placeholder_passport')}
                    value={itemData.passport}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  {clientAccount}
                </div>
              </div>
            </div>
          </div>
          <div className="text-xl py-6 font-medium ">
            {t('clients:representative_company')}
          </div>
          <div className='w-[49%]'>
            <InputWithAlert
              label={t('clients:representative_company')}
              value={itemData.representativeCompany === "BUSINESS_EXPERT" ? 'Business expert' : (itemData.representativeCompany === 'MIGRANT_EXPERT' ? 'Migrant expert' : '')}
              readOnly={true}
              disabled
              inputClassName={'h-12 mt-2 mb-4 bg-white'}
            />
          </div>
          <div className='w-full pb-16'>
            <div className='w-full flex justify-between'>
              <div className='w-[49%]'>
                <div className="text-xl font-medium py-6">
                  {t('clients:create_client_adress_block_capture')}
                </div>
                <InputWithAlert
                  label={t('address')}
                  value={itemData.street}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  label={t('post_index')}
                  value={itemData.postIndex}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  label={t('city')}
                  value={itemData.city}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
              </div>
              <div className='w-[49%]'>
                <div className="text-xl font-medium py-6 ">
                  {t('additional_information_title')}
                </div>
                <InputWithAlert
                  label={t('status')}
                  value={itemData.isActive ? t('active') : t('unactive')}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  label={t('assistant')}
                  value={`${itemData.user?.firstName} ${itemData.user?.lastName} `}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  label={t('created')}
                  value={formattedDate}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
              </div>
            </div>
          </div>
        </div>)}
    </>
  )
}
export default ClientViewPage
