import React from 'react'
import { useParams } from "react-router-dom";
import { getOneFreelancer } from '@/service/weexpertService';
import { useQuery } from 'react-query';
import { Loader } from '@/components/ProtectedRoute';
import { FreelancerViewComponent } from '@/components/freelancer/frelancer.view.component';
export default function FreelancerViewPage(props) {
    const { id } = useParams();
    const { data, isLoading, isError } = useQuery({
        queryKey: ['freelacer', id],
        queryFn: () => (getOneFreelancer(id))
    })
    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return 'error'
    }
    return (
        <FreelancerViewComponent id={id} userData={data.data} />
    );
}
