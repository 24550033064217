import React, { useEffect, useState } from "react";
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { SurveyStep1 } from '../pages/survey.step1';
import { SurveyStep2 } from '../pages/survey.step2';
import { SurveyStep3 } from '../pages/survey.step3';
import { SurveyStep4 } from '../pages/survey.step4';
import { SurveyStep5 } from '../pages/survey.step5';
import { getUser } from "../service/weexpertService";
import { useQuery, useQueryClient } from "react-query";
import { Loader } from "@/components/ProtectedRoute";

export const Survey = (props) => {

    const queryClient = useQueryClient()
    const { data: userData, isLoading: loading } = useQuery({ queryKey: ['userMeProfile'], queryFn: getUser, retry: false, refetchOnWindowFocus: false })
    const [stage, setStage] = useState('stage_0');

    const getComponentDependsOnStage = (stage) => {
        switch (stage) {
            case 'stage_0':
                return <SurveyStep2
                    next={() => setStage('stage_1')}
                    userData={userData.data}
                    back={() => { setStage('stage_initial'); queryClient.invalidateQueries({ queryKey: ['userMeProfile'] }) }} />;
            case 'stage_1':
                return <SurveyStep3
                    next={() => setStage('stage_2')}
                    userData={userData.data}
                    back={() => { setStage('stage_0'); queryClient.invalidateQueries({ queryKey: ['userMeProfile'] }) }}
                />
            case 'stage_2':
                return <SurveyStep4
                    next={() => setStage('stage_3')}
                    userData={userData.data}
                    back={() => { setStage('stage_1'); queryClient.invalidateQueries({ queryKey: ['userMeProfile'] }) }}
                />
            case 'stage_3':
                return <SurveyStep5
                    userData={userData.data}
                    back={() => { setStage('stage_2'); queryClient.invalidateQueries({ queryKey: ['userMeProfile'] }) }}
                />;
            default:
                return <SurveyStep1 userData={userData} setStage={setStage} />;
        }
    };
    return (
        <>
            {loading ? (<Loader />) : getComponentDependsOnStage(stage)}
        </>
    );
}
