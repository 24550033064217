import React, { useEffect, useState } from "react";
import AppBarForLogin from "../components/app-bar-for-main-login-pages/AppBarForLogin";
import { Input } from "@/components/ui/input"
import { Progress } from "@/components/ui/progress"
import { Button } from "@/components/ui/button"
import '../scssStyles/SurveyStep2.scss';
import { getUser, surveyStep2 } from "../service/weexpertService";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { useQuery } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { useTranslation } from "react-i18next";

export const SurveyStep2 = ({ next }) => {
    const { t } = useTranslation();
    const { data: userData, isLoading: loading } = useQuery({ queryKey: ['userMeProfile'], queryFn: getUser, retry: false, refetchOnWindowFocus: false })
    const [job, setJob] = useState('');
    const [experience, setExperience] = useState('');
    const [ldLink, setLdLink] = useState('');
    const [inputChecked, setInputChecked] = useState(false);

    useEffect(() => {
        if (!loading && userData.data.clientProfile) {
            setJob(userData.data?.clientProfile.currentJobTitle)
            setExperience(userData.data?.clientProfile.currentExperience)
            setLdLink(userData.data?.clientProfile.linkedin)
        }
    }, [userData])
    const onSaveStates = () => {
        if (!job) {
            setInputChecked(true)
            return
        }
        if (!experience) {
            setInputChecked(true)
            return
        }
        surveyStep2(job, experience, ldLink)
            .then(response => {
                next();
            });
    }
    if (loading) {
        return <Loader />
    }
    return (
        <div className={'container'}>
            <AppBarForLogin isLogined={true} userData={userData.data} />
            <div className="pt-24 pb-28" >
                <div className="text-3xl font-semibold w-96 pb-6">
                    {t('survey:step_2_capture')}
                </div>
                <div className="pb-6 text-base">
                    {t('survey:step_2_description')}
                </div>
                <div className="w-1/3 mb-4">
                    <InputWithAlert
                        label={t('survey:label_job_title')}
                        errorMessage={t('empty_data_invalid')}
                        error={inputChecked && job.trim() == ''}
                        value={job}
                        onChange={(e) => setJob(e.target.value)}
                        placeholder={t('survey:label_job_title')}
                        inputClassName={'h-12 my-2 bg-white'}
                    />
                    <InputWithAlert
                        label={t('survey:label_experience')}
                        errorMessage={t('empty_data_invalid')}
                        value={experience}
                        onChange={(e) => setExperience(e.target.value)}
                        error={inputChecked && experience.trim() == ''}
                        placeholder={t('survey:label_experience')}
                        inputClassName={'h-12 my-2 bg-white'}
                    />
                    <InputWithAlert
                        value={ldLink}
                        onChange={(e) => setLdLink(e.target.value)}
                        label={t('survey:label_linkedin')}
                        placeholder={t('survey:label_linkedin')}
                        inputClassName={'h-12 my-2 bg-white'}
                    />
                </div>
            </div>
            <Progress value={25} />
            <div className="flex flex-row justify-end pt-6">
                <Button className='w-36' onClick={() => onSaveStates()}>{t("survey:button_next")}</Button>
            </div>
        </div>
    );
}
