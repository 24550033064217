import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { editInvoice, getInvoiceById, getOneClient, getUser } from "../service/weexpertService";
import { INVOICE_EDIT, InvoiceForm } from '@/components/forms/invoice.form';
import { toast } from '@/components/ui/use-toast';
import { useTranslation } from 'react-i18next';

const InvoiceEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState(false)
  const [userData, setUserData] = useState('');
  const [loading, setLoading] = useState(true)
  const [inputChecked, setInputCheked] = useState(false);
  const [currentRole, setCurrentRole] = useState('')

  useEffect(() => {
    getUser()
      .then(response => {
        setUserData(response.data);
        setCurrentRole(response.data.roles)
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          console.error('Ошибка 403: Доступ запрещен');
        } else {
          console.error('Произошла ошибка при получении пользователя:', error.message);
        }
      })
  }, []);

  useEffect(() => {
    getInvoiceById(id)
      .then(res => {
        const invoice = res.data
        setInvoice(invoice)
        setLoading(false)
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {

          if (error.response.data && error.response.data.message === "Not found") {
            navigate('/404')
          }
        } else {
          console.error("Error:", error);
        }
        if (error.response && error.response.status === 400) {
          navigate('/404')
        } else {
          console.error("Error:", error);
        }
      });
  }, [id, invoice.invoiceId]);


  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <Box sx={{ width: "100%", height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="grey" />
          </Stack>
        </Box>
      ) : (
        <div className="relative bg-grayLightMainBg px-8">
          <InvoiceForm invoice={invoice}
            inputChecked={inputChecked}
            action={INVOICE_EDIT}
            onSave={(invoice) => {
              const {
                client,
                invoiceType,
                issueDate,
                domainDate,
                status,
                counterpartyName,
                counterpartyOrigin,
                counterpartyNip,
                counterpartyEmail,
                counterpartyType,
                counterpartyAddress,
                counterpartyPostIndex,
                counterpartyCity,
                counterpartyCountry,
                typeOfPayment,
                wayOfPayment,
                paymentStatus,
                paymentDueDate,
                paymentCurrency,
                paymentCurrencyRate,
                paymentCurrencyRateDate,
                paymentIban,
                internalComment,
                externalComment,
                rows,
                invoiceBrutto,
                invoiceTotalNetto,
                invoiceVAT,
                newInvoiceId,
                useDifferentRecipient,
                recipientCounterparty
              } = invoice;

              setInputCheked(true);
              if (!client) {
                return;
              }
              if (useDifferentRecipient && !recipientCounterparty) {
                return;
              }
              if (!invoiceType) {
                return;
              }
              if (!issueDate) {
                return;
              }
              if (!domainDate) {
                return;
              }
              if (!status) {
                return;
              }
              if (!counterpartyName) {
                return;
              }
              if (!counterpartyType) {
                return;
              }
              if (!typeOfPayment) {
                return;
              }
              if (rows.some((e) => e.name === '' || e.quantity === '' || !e.unitPriceNetto || e.vatRate === '' || e.unit === '') ||
                rows.length == 0
              ) {
                return;

              }
              const processedInvoiceId = invoice.invoiceId === newInvoiceId ? null : newInvoiceId;
              editInvoice(
                id,
                invoiceType,
                domainDate,
                status,
                counterpartyName,
                counterpartyType,
                counterpartyOrigin,
                counterpartyName,
                counterpartyPostIndex,
                counterpartyNip,
                counterpartyCity,
                counterpartyEmail,
                counterpartyCountry,
                counterpartyAddress,
                typeOfPayment,
                wayOfPayment,
                paymentStatus,
                paymentDueDate,
                paymentCurrency,
                paymentCurrencyRate,
                paymentIban,
                invoiceTotalNetto,
                invoiceBrutto,
                invoiceVAT,
                client.id,
                rows,
                internalComment,
                externalComment,
                paymentCurrencyRateDate,
                issueDate,
                useDifferentRecipient,
                {
                  counterpartyType: recipientCounterparty.counterpartyType,
                  name: recipientCounterparty.counterpartyName,
                  address: recipientCounterparty.counterpartyAddress,
                  postIndex: recipientCounterparty.counterpartyPostIndex,
                  city: recipientCounterparty.counterpartyCity,
                  email: recipientCounterparty.counterpartyEmail,
                  country: recipientCounterparty.counterpartyCountry,
                  NIP: recipientCounterparty.counterpartyNip,
                  origin: recipientCounterparty.counterpartyOrigin
                },
                processedInvoiceId
              )
                .then((response) => {
                  if (response.status === 200) {
                    toast({
                      title: t("alerts:toast_succes_invoice_edit_title"),
                      description: t("alerts:toast_succes_invoice_edit_description"),
                    })
                    navigate('/admin/invoices/sales')
                  }
                })
                .catch((error) => {
                  toast({
                    title: t("alerts:toast_error_invoice_title"),
                    description: t("alerts:toast_error_invoice_description"),
                    variant: "destructive"
                  })

                  if (error.response && error.response.status === 400) {
                    console.error(error);
                  } else {
                    console.error(error);
                  }
                });

            }} />
        </div>
      )}
    </Box>
  )
}

export default InvoiceEditPage;
