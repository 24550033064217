import * as React from 'react';
import { useState, useEffect } from 'react';
import { InputWithAlert } from '../ui/input-with-alert';
import MultipleSelector from "@/components/ui/multiple-selector";
import { SKILLS_TYPE, TOOLS_AND_TECHNOLOGY_TYPE, PROGRAMMING_LANGUAGE_TYPE, OPTIONS_LANGUAGE, OPTIONS_STUDY_FIELD, SKILLS_TYPES, EDUCATION_TYPE, TARGET_KEYS, FRAMEWORK_TYPE, OPTIONS_LANGUAGE_LEVEL, EXISTING_ABILITY_ID, AVAILABILITY_HOURS } from "../../constants";
import { Combobox } from "@/components/ui/combobox";
import { TrashIcon} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { useTranslation } from 'react-i18next';

import { toast } from '../ui/use-toast';
import { SelectWithAlert } from '../ui/select-with-alert';

const LanguageAbility = ({ onLanguageChange, onLanguageDelete, languageEntry, edit, readOnly, takenLanguages }) => {
    const { t } = useTranslation();
    const { id, proficiency, language } = languageEntry;
    const [level, setLevel] = useState(proficiency);
    const [currentLanguage, setCurrentLangauge] = useState(language);

    useEffect(() => {
        onLanguageChange(id, currentLanguage, level);
    }, [currentLanguage, level])

    return <div key={id} >
        <div className="flex justify-between">
            <Combobox
                className={'w-1/2 mr-2'}
                label={t("survey:label_language")}
                value={currentLanguage}
                disabled={readOnly}
                placeholder={t("survey:placeholder_languages")}
                options={OPTIONS_LANGUAGE().map(e => {
                    return {
                        value: e.id,
                        label: e.value
                    }
                })
                    .filter(e => (takenLanguages.includes(e.value) === false))
                }
                onChange={(newValue) => {
                    setCurrentLangauge(newValue)
                }}
            />
            <Combobox
                className={readOnly ? 'w-1/2 ml-2 ' : 'w-1/2 ml-2 mr-3'}
                label={t("survey:label_knowledge")}
                value={level}
                disabled={readOnly}
                placeholder={t("survey:label_knowledge")}
                options={OPTIONS_LANGUAGE_LEVEL().map(e => {
                    return {
                        value: e.id,
                        label: e.value
                    }
                })}
                onChange={(newValue) => {
                    setLevel(newValue)
                }}
            />
            {!readOnly && (
                <div
                    className={"flex items-center justify-center cursor-pointer"}
                    onClick={() => onLanguageDelete(id)}
                >
                    <TrashIcon />
                </div>
            )}
        </div>
    </div>
}

export function FreelancerProfile(props) {
    const { t } = useTranslation();
    const {
        userData,
        edit,
        job,
        setJob,
        experience,
        setExperience,
        ldLink,
        setLdLink,
        abilities,
        setShowSpecificSkills,
        selectedSkills,
        setSelectedSkills,
        selectedTools,
        setSelectedTools,
        selectedProgrammingLanguages,
        setSelectedProgrammingLanguages,
        selectedFrameworks,
        setSelectedFrameworks,
        clientProfile,
        languagesData,
        setLanguagesData,
        inputChecked,
        setInputChecked,
        selectedEducation,
        setSelectedEducation,
        selectedStudy,
        setSelectedStudy,
        checked,
        setChecked,
        currentRate,
        setCurrentRate,
        maxHours,
        setMaxHours,
        allertMessage,
        success,
        error,
        id
    } = props

    useEffect(() => {
        const isSkillsSelected = selectedSkills.some(skill => TARGET_KEYS.includes(skill.value));
        setShowSpecificSkills(isSkillsSelected);
    }, [selectedSkills]);

    const onLanguageUpdate = (id, language, proficiency) => {
        if (languagesData.some(l => l.language && l.language?.value === language?.value && id != l.id)) {
            toast({
                title: t('survey:error_duplicate_language'),
                variant: "destructive"
            })
        }
        setLanguagesData((prevState) => {
            return prevState.map(e => {
                if (e.id == id) {
                    return {
                        id: id,
                        language,
                        proficiency
                    }
                }
                return e;
            })
        })
    }
    const onLanguageDelete = (id) => {
        setLanguagesData((prevState) => {
            return prevState.filter(e => e.id != id);
        })
    }
    const languageItem = languagesData.map((item) => (
        <div key={item.id + item.language}>
            <LanguageAbility
                languageEntry={item}
                readOnly={!edit}
                edit={edit}
                onLanguageChange={onLanguageUpdate}
                onLanguageDelete={onLanguageDelete}
                takenLanguages={languagesData.filter(l => l.langauges).map(l => l.languages.value)}
            />
        </div>
    ))
    const addNextLanguageKnowleges = () => {
        setLanguagesData((prev) => prev.concat({
            id: prev.length + 1,
            language: null,
            proficiency: null
        }));
    }
    const handleRateChange = (event) => {
        const value = event.target.value;
        if (value === '' || (/^\d+$/.test(value) && parseInt(value) >= 1 && parseInt(value) <= 500)) {
            setCurrentRate(value);
        }
    };
    return (
        <>
            <div className='pt-10 pb-10 '>
                <InputWithAlert
                    label={t("survey:label_job_title")}
                    errorMessage={t('empty_data_value')}
                    error={inputChecked && !job}
                    value={job}
                    onChange={(e) => setJob(e.target.value)}
                    placeholder={t("survey:label_job_title")}
                    disabled={!edit}
                    inputClassName={'h-12 my-2 focus-visible:border-[0px]'}
                />
                <InputWithAlert
                    label={t("survey:label_experience")}
                    errorMessage={t('empty_data_value')}
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                    error={inputChecked && !experience}
                    placeholder={t("survey:label_experience")}
                    disabled={!edit}
                    inputClassName={'h-12 my-2 focus-visible:border-[0px]'}
                />
                <InputWithAlert
                    value={ldLink}
                    onChange={(e) => setLdLink(e.target.value)}
                    label={t("survey:label_linkedin")}
                    disabled={!edit}
                    placeholder={t("survey:label_linkedin")}
                />
                <MultipleSelector
                    creatable={true}
                    className="min-h-12 mt-2"
                    placeholder={t("survey:label_Skills")}
                    badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
                    value={selectedSkills}
                    disabled={!edit}
                    inputClassName={'h-12 my-2 focus-visible:border-[0px]'}
                    error={inputChecked && !selectedSkills}
                    errorMessage={t("survey:error_select")}
                    label={t("survey:label_Skills")}
                    onChange={(newSelected) => {
                        setSelectedSkills(newSelected)
                    }}
                    options={SKILLS_TYPE()}
                />
                <MultipleSelector
                    creatable={true}
                    className="min-h-12 mt-2"
                    placeholder={t("survey:placeholder_languages")}
                    badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
                    value={selectedProgrammingLanguages}
                    disabled={!edit}
                    label={t("survey:label_programming_languages")}
                    onChange={(newSelected) => {
                        setSelectedProgrammingLanguages(newSelected)
                    }}
                    options={PROGRAMMING_LANGUAGE_TYPE()}
                />
                <MultipleSelector
                    creatable={true}
                    className="min-h-12 mt-2"
                    placeholder={t("survey:placeholder_frameworks")}
                    badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
                    value={selectedFrameworks}
                    disabled={!edit}
                    label={t("survey:label_frameworks")}
                    options={FRAMEWORK_TYPE()}
                    onChange={(newSelected) => {
                        setSelectedFrameworks(newSelected)
                    }}
                />
                <MultipleSelector
                    creatable={true}
                    className="min-h-12 mt-2"
                    placeholder={t("survey:placeholder_tools")}
                    badgeClassName={"bg-emerald-100 hover:bg-emerald-100 text-black"}
                    value={selectedTools}
                    disabled={!edit}
                    errorMessage={'please select 1 skill'}
                    label={t("survey:label_tools")}
                    options={TOOLS_AND_TECHNOLOGY_TYPE()}
                    onChange={(newSelected) => { setSelectedTools(newSelected) }}
                />
                <Combobox
                    label={t("survey:label_education")}
                    placeholder={t("survey:label_education")}
                    creatable
                    value={selectedEducation}
                    error={inputChecked && !selectedEducation}
                    errorMessage={t('emtpy_data_invalid')}
                    disabled={!edit}
                    options={EDUCATION_TYPE().map(e => {
                        return {
                            value: e.id,
                            label: e.value
                        }
                    })}
                    onChange={(newValue) => {
                        setSelectedEducation(newValue)
                    }}
                    readOnly={!edit}
                    className={"mt-2"}
                />
                <Combobox
                    label={t("survey:label_study")}
                    value={selectedStudy}
                    placeholder={t("survey:label_study")}
                    creatable
                    className={"mt-2"}
                    disabled={!edit}
                    error={inputChecked && !selectedStudy}
                    errorMessage={t('emtpy_data_invalid')}
                    options={OPTIONS_STUDY_FIELD().map(e => {
                        return {
                            value: e.id,
                            label: e.value
                        }
                    })}
                    onChange={(newValue) => {
                        setSelectedStudy(newValue)
                    }}
                />
                <div className="knowlege-of-language-wrapper">
                    {languageItem}
                </div>
                <>
                    {edit && (<Button onClick={() => { addNextLanguageKnowleges(languagesData); }}>+</Button>)}
                    <div className="w-full flex flex-row justify-start items-center pt-8 pb-8">
                        <Switch
                            onClick={() => { if (edit) setChecked(!checked); }}
                            checked={checked}
                        />
                        <div className="pl-4">{t("users:freelancer_profile_agreement_projects")}</div>
                    </div>
                    {checked && (
                        <div className='pb-20'>
                            <SelectWithAlert
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                label={t('survey:label_availability')}
                                placeholder={t('survey:placeholder_availability')}
                                value={maxHours}
                                disabled={!edit}
                                onChangeValue={(e) => setMaxHours(e)}
                                options={
                                    (() => {
                                       const options = AVAILABILITY_HOURS(t);
                                        return options.some(e => e.value == maxHours) ? options: options.concat({value: maxHours, label: maxHours})
                                    })()
                                }
                            />
                            <InputWithAlert
                                label={t("survey:label_rate")}
                                errorMessage={t("survey:error_empty")}
                                value={currentRate}
                                onChange={handleRateChange}
                                disabled={!edit}
                                placeholder={t("survey:rate_range") + " USD$"}
                                type="number"
                                className={"h-12 my-2 bg-white"}
                            />
                        </div>
                    )}
                </>

            </div>
        </>
    );

}
