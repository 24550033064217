import * as React from 'react';
import { useState } from 'react';
import { InfoIcon } from 'lucide-react';
import { InputWithAlert } from '../ui/input-with-alert';

export function MainInformationEdit(props) {
    const { userData } = props;

    return (
        <div className='w-full ml-4'>
            <div className=' pt-10 flex justify-between items-center'>
                <div className='w-6/12'>

                    <InputWithAlert
                        label="Name"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Name'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2 h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />
                    <InputWithAlert
                        label="Phone"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Phone'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />
                     <InputWithAlert
                        label="Citizenship"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Citizenship'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />
                     <InputWithAlert
                        label="Pesel"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Pesel'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />
                    <InputWithAlert
                        label="Banking account"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Banking account'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />

                </div>
                <div className='w-6/12'>
                    <InputWithAlert
                        label="Surname"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Surname'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />
                    <InputWithAlert
                        label="Email"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Email'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />
                    <InputWithAlert
                        label="Date of birth"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Date of birth'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />
                     <InputWithAlert
                        label="Passport"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Passport'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />
                     <InputWithAlert
                        label="Banking account of the client"
                        errorMessage={"Empty value"}
                        //error={inputChecked && job.trim() == ''}
                        // value={job}
                        // onChange={(e) => setJob(e.target.value)}
                        placeholder={'Banking account of the client'}
                        // readOnly={!edit}
                        inputClassName={'w-[96%] mt-2 mb-2  h-12 focus-visible:border-[0px] focus-visible:border-greenText'}
                    />

                </div>
            </div>
        </div>
    );

}
