
import { useState } from "react";
import { AnnexForm } from "../forms/annex.form";
import { BaseDialog } from "./dialog"
import { createAnnex } from "@/service/weexpertService";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

export default function AnnexCreatePopup(props) {
  const { t } = useTranslation();
  const { initialContract, annex, callback, text } = props
  const [open, setOpen] = useState(false)
  const queryClient = useQueryClient()
  return (
    <BaseDialog
      buttonText={text}
      open={open}
      setOpen={setOpen}
    >
      <div className="w-[700px] h-[400px] px-8 py-12">
        <AnnexForm
          annex={annex}
          initialContract={initialContract}
          onSaved={(contractId, changes, state, acceptedDate, signatory) => {
            callback(contractId, state, changes, acceptedDate, signatory)
              .then(() => {
                queryClient.invalidateQueries()
              })
            setOpen(false)
          }
          }
        />
      </div >
    </BaseDialog>
  );
}
