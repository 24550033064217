"use client"

import { Check, ChevronsUpDown } from "lucide-react"
import * as React from "react"
import { useEffect } from "react"

import { Button } from "@/components/ui/button"
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { CommandList } from "cmdk"

import { AlertCircle } from "lucide-react"
import { Alert, AlertTitle } from "./alert"
import { Label } from "./label"
import { useDebounce } from "./multiple-selector"
export function Combobox({ options,
  onChange,
  placeholder,
  label,
  error,
  errorMessage,
  commandProps,
  value,
  delay,
  disabled,
  className,
  creatable = false,
  onSearch = false,
  data_testid = null }) {
  const [open, setOpen] = React.useState(false)
  const [currentValue, setCurrentValue] = React.useState(value ?? null)
  const [inputValue, setInputValue] = React.useState("")
  useEffect(() => {
    onChange(currentValue);
  }, [currentValue])

  const debouncedSearchTerm = useDebounce(value, delay || 500)
  const CreatableItem = () => {
    if (!creatable) return undefined
    const Item = (
      <CommandItem
        value={inputValue}
        className="cursor-pointer"
        onMouseDown={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onSelect={value => {
          setCurrentValue({ value: inputValue, label: inputValue })
          setInputValue("")
          setOpen(false)
        }}
      >{`Create "${inputValue}"`}</CommandItem>
    )

    if (!onSearch && inputValue.length > 0) {
      return Item
    }
    if (onSearch && debouncedSearchTerm.length > 0 && !isLoading) {
      return Item
    }

    return undefined
  }
  const commandFilter = React.useCallback(() => {
    if (commandProps?.filter) {
      return commandProps.filter
    }

    if (creatable) {
      return (value, search) => {
        return value.toLowerCase().includes(search.toLowerCase()) ? 1 : -1
      }
    }
    return undefined
  }, [creatable, commandProps?.filter])
  return (
    <div className={cn('flex flex-col relative pb-4', className ?? '')}>
      <Label htmlFor="inputId" className="pb-2">{label}</Label>
      <Popover open={open} onOpenChange={setOpen} className="w-full " data-testid={"IT"}>
        <PopoverTrigger asChild className={disabled ? 'bg-inherit hover:bg-inherit cursor-default':'bg-white'}>
          <Button
            variant="outline"
            role="combobox"
            data-testid={data_testid}
            // aria-expanded={open}
            className="w-full justify-between h-12"
          >
            {currentValue
              ? options.find((option) => option.value == currentValue.value)?.label ?? placeholder
              : placeholder}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        {!disabled && (
          <PopoverContent className="w-80" align="start">
            <Command
              shouldFilter={
                commandProps?.shouldFilter !== undefined
                  ? commandProps.shouldFilter
                  : !onSearch
              }
              filter={commandFilter()}

            >
              <CommandList className="absolute top-0 left-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
                <CommandInput
                  data-testid={data_testid + '_input'}
                  placeholder={placeholder ?? ''}
                  onValueChange={value => {
                    setInputValue(value)
                  }}
                />
                <CommandGroup>
                  {options.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={() => {
                        setCurrentValue(options.find((p) => p.value == option.value))
                        setOpen(false)
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          currentValue && currentValue.value === option.value ? "opacity-100" : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
                {CreatableItem()}
              </CommandList>
            </Command>
          </PopoverContent>
        )}
      </Popover>
      {error && (<div className="mt-4">
        <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
          <AlertCircle className="h-4 w-4 !static !mr-2" />
          <AlertTitle className="m-0 !p-0">
            {errorMessage}
          </AlertTitle>
        </Alert>
      </div>)
      }

    </div>
  )
}
