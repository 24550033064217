import { BillForm } from "@/components/forms/bill.form"
import { toast } from "@/components/ui/use-toast"
import { editBill, getBill, getUser } from "@/service/weexpertService"
import { format } from "date-fns"
import { Loader } from "lucide-react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

export const BillEditPage = (props) => {

  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: bill, isLoading } = useQuery({
    queryKey: ['bill', id], queryFn: () => {
      return getBill(id)
    }
  })

  const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });
  const onSaved = (
    contract,
    contractType,
    taxDebuctible,
    pit,
    grossAmount,
    emerytalneRentowe,
    chorobowe,
    zdrowotna,
    status,
    bankAccount,
    conclusionDate,
    acceptedDate,
    specificationStage,
    signatory,
    comment,
    billId
  ) => {
    return editBill(
      id,
      contract.id,
      status,
      contractType,
      taxDebuctible,
      pit,
      format(conclusionDate, 'yyyy-MM-dd'),
      +grossAmount,
      emerytalneRentowe,
      chorobowe,
      zdrowotna,
      bankAccount,
      format(acceptedDate, 'yyyy-MM-dd'),
      signatory,
      comment,
      specificationStage ? specificationStage : null,
      billId
    ).then((res) => {
      toast({
        title: t("alerts:succes"),
      })
      navigate('/bills')
    }).catch((res) => {
      toast({
        title: t("alerts:error_send_fail"),
        variant: "destructive"
      })
    })
  }
  if (isLoading) {
    return <Loader />
  }
  if (isUserLoading) {
    return <Loader />
  }
  if (user.data.roles === 'CLIENT') {
    navigate('/404')
  }

  return <div className="relative bg-grayLightMainBg px-8">
    <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
      <div className="flex justify-between pt-8  items-center ">
        <div className=" w-5/12  text-4xl ">
          {t("bills:bill_edit")}
        </div>
      </div>
    </div>
    <div className='w-full py-8'>
      <BillForm
        onSaved={onSaved}
        className={'w-full'}
        initialContract={bill.data.bill.contract}
        bill={bill.data.bill}
      />
    </div>
  </div>
}

