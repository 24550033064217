import React, { useEffect, useState } from "react";
import { getContracts } from "@/service/weexpertService";
import { useTranslation } from "react-i18next";
import { Search } from "../../components/ui/combobox-search";
import { InputWithAlert } from "../../components/ui/input-with-alert";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { TrashIcon } from "lucide-react";
import { v4 as uuidv4 } from 'uuid';
import { format } from "date-fns";
import { DatePicker } from "../../components/ui/date-picker";
import { AlertCircle } from "lucide-react";
import { Alert, AlertTitle } from "@/components/ui/alert";
import { SelectWithAlert } from "../ui/select-with-alert";
import { CONTRACT_TYPE, Signatories, parseNumber } from "@/constants";
const SpecificationPosition = ({ id, text, grossAmount, onDelete, onChange, endDate, order, onPositionNumberChange }) => {
    const { t } = useTranslation()
    const [currentText, setText] = useState(text);
    const [currentValue, setValue] = useState(+grossAmount || undefined);
    const [dateOfEnd, setDateOfEnd] = useState(endDate || null);

    const handleDateChange = (date) => {
        if (date) {
            const formattedDate = format(date, 'yyyy-MM-dd');
            setDateOfEnd(formattedDate);
            onChange({ id, text: currentText, grossAmount: currentValue, endDate: formattedDate });
        } else {
            setDateOfEnd(null);
            onChange({ id, text: currentText, grossAmount: currentValue, endDate: null });
        }
    };

    useEffect(() => {
        onChange({ id, text: currentText, grossAmount: currentValue, endDate: dateOfEnd });
    }, [currentText, currentValue, dateOfEnd]);

    return (
        <div key={id} className="w-full flex justify-between items-center py-2">
            <div className="w-1/12 text-center mr-4">
                <InputWithAlert
                    type="number"
                    value={order}
                    onChange={(e) => onPositionNumberChange(id, e.target.value)}
                    inputClassName={'h-[60px] w-[50px]  p-2 bg-white text-center'}

                />
            </div>
            <div className="w-5/12">
                <Textarea
                    placeholder={t("specifications:spec_text")}
                    value={currentText}
                    onChange={(e) => setText(e.target.value)}
                    className={'h-[60px] bg-white pt-5'}
                />
            </div>
            <div className="w-3/12 ml-4">
                <InputWithAlert
                    placeholder={t("bills:gross_amount")}
                    value={currentValue}
                    onChange={(e) => setValue(parseNumber(e.target.value))}
                    inputClassName={'h-[60px] w-full bg-white'}
                />
            </div>
            <div className="w-3/12 ml-4">
                <DatePicker
                    label={t("specifications:spec_end_date")}
                    date={dateOfEnd ? new Date(dateOfEnd) : null}
                    defaultMonth={dateOfEnd ? new Date(dateOfEnd) : new Date()}
                    setDate={handleDateChange}
                    inputClassName={'h-[60px] min-w-[110px] text-base mb-6'}
                />
            </div>
            <div className="pl-4">
                <Button className="h-12" onClick={() => onDelete(id)}>
                    <TrashIcon />
                </Button>
            </div>
        </div>
    );
};

export const SpecificationForm = ({ onSaved, initialData = null, action = 'creation' }) => {
    const { t } = useTranslation();
    const [contract, setContract] = useState(initialData ? initialData.contract : undefined);
    const [changes, setChanges] = useState(initialData ? initialData.specificationItems : []);
    const [descriptionComment, setDescriptionComment] = useState(initialData ? initialData.description : undefined);
    const [functionalityComment, setFunctionalityComment] = useState(initialData ? initialData.functionality : undefined);
    const [signatory, setSignatory] = useState(initialData ? initialData.signatory : '');
    const [inputChecked, setInputChecked] = useState(false);

    const onDelete = (id) => {
        setChanges((prev) => prev.filter(e => e.id !== id));
    };

    const handleChange = ({ id, text, grossAmount, endDate }) => {
        setChanges((prev) => {
            const index = prev.findIndex(el => el.id === id);
            const newChanges = [...prev];
            newChanges[index] = { ...newChanges[index], text, grossAmount, endDate };
            return newChanges;
        });
    };

    const handlePositionNumberChange = (id, newNumber) => {
        setChanges((prev) => {
            const index = prev.findIndex(el => el.id === id);
            const newChanges = [...prev];
            newChanges[index] = { ...newChanges[index], order: newNumber };
            return newChanges;
        });
    };

    const addNewPosition = () => {
        const newPositionNumber = changes.length > 0 ? Math.max(...changes.map(e => e.order)) + 1 : 1;
        setChanges((prev) => [
            ...prev,
            { id: uuidv4(), text: '', grossAmount: 0, endDate: null, order: newPositionNumber }
        ]);
    };
    return (
        <div className="relative bg-grayLightMainBg px-8">
            <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
                <div className="flex justify-between py-8 items-center">
                    <div className="w-5/12 text-4xl">{t("specifications:spec")}</div>
                    <div>
                        <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                            onClick={() => {
                                setInputChecked(true)
                                onSaved(
                                    contract,
                                    descriptionComment,
                                    functionalityComment,
                                    signatory,
                                    changes
                                )
                            }}>
                            {t("common:button_save")}
                        </Button>
                    </div>
                </div>
            </div>
            <div className='w-full md:w-9/12 lg:w-8/12 xl:w-7/12 py-8'>
                <Search
                    fetchOption={(query) => {
                        return getContracts(100, 0, query, [{ field: 'contractType', value: [CONTRACT_TYPE.UMOWA_O_DZIELO] }])
                            .then((res) => {
                                return res.data.contracts.map(contract => {
                                    return {
                                        label: contract.contractId + ` (${contract.client.firstName} ${contract.client.lastName})`,
                                        value: contract.id,
                                        contract: contract
                                    };
                                });
                            });
                    }}
                    placeholder={t('contracts:contract')}
                    label={t('contracts:contract')}
                    value={contract ? contract.contractId : null}
                    error={inputChecked && !contract || (contract && contract.specifications?.length > 0 && action === 'creation')}
                    errorMessage={
                        inputChecked && !contract ?
                            t('empty_data_invalid') :
                            t('alerts:error_contract_create')
                    }
                    onChange={(e) => setContract(e.contract)}
                />
                {
                    contract && (<SelectWithAlert
                        label={t("bills:bills_signatory")}
                        options={Signatories[contract.representativeCompany]}
                        value={signatory}
                        onChangeValue={setSignatory}
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />)
                }


                <div className='text-sm font-medium pt-4 pb-6'>
                    {t('specifications:spec_description')}
                </div>
                <Textarea
                    placeholder={t('specifications:spec_description')}
                    value={descriptionComment}
                    onChange={(e) => setDescriptionComment(e.target.value)}
                    error={inputChecked && !descriptionComment}
                    errorMessage={t('empty_data_invalid')}
                    className="h-20 bg-white"
                />
                <div className='text-sm font-medium pt-4 pb-6'>
                    {t('specifications:spec_functionality')}
                </div>
                <Textarea
                    placeholder={t('specifications:spec_functionality')}
                    value={functionalityComment}
                    onChange={(e) => setFunctionalityComment(e.target.value)}
                    error={inputChecked && !functionalityComment}
                    errorMessage={t('empty_data_invalid')}
                    className="h-20 bg-white"
                />
                <div className='w-full pt-8'>
                    {changes.map((e) => (
                        <SpecificationPosition
                            key={e.id}
                            id={e.id}
                            text={e.description}
                            grossAmount={e.grossAmount}
                            endDate={e.endDate}
                            onDelete={onDelete}
                            onChange={handleChange}
                            order={e.order}
                            onPositionNumberChange={handlePositionNumberChange}
                        />
                    ))}
                </div>
                <div className='w-full pt-8'>
                    <InputWithAlert
                        label={t('bills:gross_amount')}
                        value={changes.reduce((acc, e) => (acc + (e.grossAmount ? +e.grossAmount : 0)), 0).toFixed(2)}
                        disabled={true}
                        inputClassName={'h-12  mb-4 bg-white'}
                    />

                </div>

                <div className="w-full flex justify-between items-center py-8">
                    {(inputChecked && changes.length < 1) && (
                        <div className="mt-4">
                            <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
                                <AlertCircle className="h-4 w-4 !static !mr-2" />
                                <AlertTitle className="m-0 !p-0">
                                    {t('empty_data_invalid')}
                                </AlertTitle>
                            </Alert>
                        </div>
                    )}
                    <Button className="w-1/4 h-12" variant="outline" onClick={addNewPosition}>
                        {t("button_add")}
                    </Button>
                </div>
            </div>
        </div>
    );
};
