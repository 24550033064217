import { AlertCircle } from "lucide-react";
import * as React from "react"

import { cn } from "../../lib/utils"
import { Alert, AlertTitle } from "./alert";
import { Input } from "./input";
import { Label } from "./label";
import { v4 as uuidv4 } from 'uuid';
const InputWithAlert = React.forwardRef(({ inputId = uuidv4(), inputClassName = 'h-12 mt-1', error = false, errorMessage = null, type, label, ...props }, ref) => {
  return (
    <>
      <Label htmlFor="inputId" className="pb-1">{label}</Label>
      <Input id={inputId} readOnly={props.disabled} className={` ${props.disabled ? '' : 'bg-white'} ${inputClassName}`} type={type} {...props} ref={ref} />
      {error && (<div className="mt-4">
        <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
          <AlertCircle className="h-4 w-4 !static !mr-2" />
          <AlertTitle className="m-0 !p-0">
            {errorMessage}
          </AlertTitle>
        </Alert>
      </div>)
      }
    </>
  );
})

InputWithAlert.displayName = "Input"
export { InputWithAlert }
