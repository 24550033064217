import React, { useState, useEffect } from "react";
import AccountForm from "../components/account-components/AccountForm";
import ChangePass from "../components/account-components/ChangePass";
import { ClientProfile } from "@/components/account-components/client.profile";

const AccountPage = (props) => {
  const {userData} = props;

  const [isStangingPasswordStarted, setIsStangingPasswordStarted] = useState(false);
  const [userDataLoaded, setUserDataLoaded] = useState(false);

  useEffect(() => {
    if (userData) {
      setUserDataLoaded(true);
    }
  }, [userData]);

  if (!userDataLoaded) {
    return <div>Loading...</div>;
  }

  const userRoles = userData && userData.roles;


  return (
    <>
      {isStangingPasswordStarted ?
        <ChangePass
          userData={userData}
          togglePasswordChange={setIsStangingPasswordStarted} /> :
        (userRoles === 'CLIENT' ?
         <ClientProfile  userData={userData}/>
         :
          <AccountForm
            userData={userData}
            handleNewPass={setIsStangingPasswordStarted} />
        )
      }
    </>
  );
}

export default AccountPage;