import React from "react";
import AppBarForLogin from "../components/app-bar-for-main-login-pages/AppBarForLogin";
import { Button } from "@/components/ui/button"
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useQuery } from "react-query";
import { getUser } from "@/service/weexpertService";
import { useTranslation } from 'react-i18next';

export const SurveyStep1 = (props) => {
    const { t } = useTranslation();
    const { setStage} = props
    const { data: userData, isLoading: loading } = useQuery({ queryKey: ['userMeProfile'], queryFn: getUser, retry: false, refetchOnWindowFocus: false })
    
    const explanationsData = [
        {
            icon: <PersonOutlineIcon fontSize="medium" />,
            question: t('survey:step_1_guide_item_1')
        },
        {
            icon: <ReceiptLongIcon fontSize="medium" />,
            question:  t('survey:step_1_guide_item_2')
        },
        {
            icon: <PeopleOutlineIcon fontSize="medium" />,
            question:  t('survey:step_1_guide_item_3')
        },
    ]

    const explanationsItem = explanationsData.map((item, index) => (
        <div className="w-full flex justify-between items-center py-4 border-b-[1px]" key={index}>
            <div className="survey-item-icon">{item.icon}</div>
            <div className="pl-3.5">{item.question}</div>
        </div>
    ))

    return (
        <div className='container'>
            <AppBarForLogin />
            <div className="pt-24 pb-28 w-5/12">
                <div className="survey-text-block" >
                    <div className="text-3xl font-semibold pb-6">
                        {t('survey:step_1_capture_part_1')} {userData && `${userData.data.firstName}. `}  {t('survey:step_1_capture_part_2')}

                    </div>
                    {explanationsItem}
                    <div className="flex justify-between items-center pt-5">
                        <div className="text-base opacity-70">
                            {t('survey:step_1_explanation')}
                        </div>
                        <Button className="w-36" onClick={() => setStage('stage_0')}> {t('survey:step_1_button_start')} </Button>
                    </div>
                </div>
                <div className="rectangle-progress">
                    <div className="rectrangle-progress-bar">
                    </div>
                </div>
            </div>
        </div>
    );
}
