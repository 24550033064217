import { ContractForm } from "@/components/forms/contract.form"; import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { CONTRACT_PIT, CONTRACT_STATE, CONTRACT_TAX_DEDUCTIBLE, CONTRACT_TYPE, DECLARATION_LANGUAGE } from "@/constants";
import { createContract, getUser } from "@/service/weexpertService";
import { format, isBefore } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

export function ContractCreatePage(props) {
  const [client, setClient] = useState(null);
  const [contractType, setContractType] = useState(CONTRACT_TYPE.UMOWA_O_DZIELO);
  const [conclusionDate, setConclusionDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [denouementDate, setDenouementDate] = useState();
  const [grossAmount, setGrossAmount] = useState(0.00);
  const [taxDeductible, setTaxDeductible] = useState();
  const [pit, setPit] = useState();
  const [subjectMetter, setSubjectMetter] = useState('');
  const [startText, setStartText] = useState('');
  const [student, setStudent] = useState(false);
  const [dontUseStartDate, setDontUseStartDate] = useState(false);
  const [youngDiscount, setYoungDiscount] = useState(false);
  const [contractState, setContractState] = useState(CONTRACT_STATE.Pending);
  const [signatory, setSignatory] = useState('');
  const [declarationLanguage, setDeclarationLanguage] = useState(DECLARATION_LANGUAGE.RU);
  const [contractPayType, setContractPayType] = useState('');
  const [representativeCompany, setRepresentativeCompany] = useState('');
  const [inputChecked, setInputChecked] = useState(false);
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });
  const onSubmit = () => {
    setInputChecked(true)
    if (!client ||
      !contractType ||
      !conclusionDate ||
      !startDate ||
      !endDate ||
      !grossAmount ||
      !taxDeductible ||
      !pit ||
      !subjectMetter ||
      isBefore(endDate, startDate) ||
      isBefore(startDate, conclusionDate) ||
      !signatory ||
      !declarationLanguage ||
      !contractPayType ||
      (contractState == CONTRACT_STATE.Ended && !denouementDate) ||
      (dontUseStartDate && !startText)
    ) {
      return;
    }
    createContract(client.id,
      contractState,
      contractType,
      format(conclusionDate, 'yyyy-MM-dd'),
      format(startDate, 'yyyy-MM-dd'),
      format(endDate, 'yyyy-MM-dd'),
      +grossAmount,
      pit,
      subjectMetter,
      taxDeductible,
      student,
      signatory,
      youngDiscount,
      declarationLanguage,
      contractPayType,
      denouementDate ? format(denouementDate, 'yyyy-MM-dd') : null,
      dontUseStartDate,
      startText,
      representativeCompany
    ).then((response) => {
      if (response.status === 200) {
        toast({
          title: t("alerts:toast_succes_contract_create_title"),
          description: t("alerts:toast_succes_invoice_create_description"),
        })
        queryClient.invalidateQueries()
        navigate('/contracts');
      }
    })
      .catch((error) => {
        toast({
          title: t("alerts:toast_error_counterparty_title"),
          description: "alerts:toast_error_invoice_description",
          variant: "destructive"
        })
      });

  }
  if (isUserLoading) {
    return <Loader />
  }
  if (user.data.roles === 'CLIENT') {
    navigate('/404')
  }

  return (<div className="w-full px-8 py-10 bg-grayLightMainBg">
    <div className="text-4xl ">{t("contracts:contract")}</div>
    <ContractForm
      inputChecked={inputChecked}
      client={client}
      setClient={setClient}
      contractType={contractType}
      setContractType={setContractType}
      conclusionDate={conclusionDate}
      setConclusionDate={setConclusionDate}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      grossAmount={grossAmount}
      setGrossAmount={setGrossAmount}
      taxDeductible={taxDeductible}
      setTaxDeductible={setTaxDeductible}
      pit={pit}
      setPit={setPit}
      subjectMetter={subjectMetter}
      setSubjectMetter={setSubjectMetter}
      student={student}
      setStudent={setStudent}
      contractState={contractState}
      setContractState={setContractState}
      signatory={signatory}
      setSignatory={setSignatory}
      setYoungDiscount={setYoungDiscount}
      youngDiscount={youngDiscount}
      declarationLanguage={declarationLanguage}
      setDeclarationLanguage={setDeclarationLanguage}
      contractPayType={contractPayType}
      setContractPayType={setContractPayType}
      denouementDate={denouementDate}
      setDenouementDate={setDenouementDate}
      dontUseStartDate={dontUseStartDate}
      setDontUseStartDate={setDontUseStartDate}
      startText={startText}
      setStartText={setStartText}
      representativeCompany={representativeCompany}
      setRepresentativeCompany={setRepresentativeCompany}
    />
    <div className="pt-5 flex justify-end">
      <Button className="w-36 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
        onSubmit();
      }}>
        {t("button_add")}
      </Button>
    </div>

  </div >
  )
}
